.ViewJob {
  .jobDetail {
    font-family: 'Poppins', sans-serif;
    color: black;
    background-color: #fff;

    .divider {
      font-weight: 200;
    }

    .customExpandIcon {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .jobDetailHeader {
      .jobDetailHeaderLeft {
        .title {
          margin-right: 8px;
          font-size: 16px;
          font-weight: 500;
        }

        .status {
          border-radius: 110px;
          padding: 5px 11px;
          font-weight: 500;
        }
      }

      .accordionExpandIcon {
        border-radius: 50%;
        height: 28px;
        width: 28px;
        background: #ebf1fc;
      }
    }

    .jobDetailContent {
      font-size: 14px;
      padding: 0 24px;
      .ant-collapse-content-box {
        padding: 0;
      }
      .ant-collapse-header {
        display: flex;
        align-items: center;
        padding-right: 0;
        padding-left: 0;
      }

      .jobDetailContentSectionOne {
        font-size: 14px;
        font-weight: 400;
        list-style: none;
        padding: 0;
        margin: 0;

        .contentIcon {
          color: #0a7f8f;
          font-size: 15px;
        }
      }

      .contentDivider {
        margin-top: 24px;
        margin-bottom: 24px;
        border: 0.5px solid #f0f0f0;
      }

      .jobDetailContentSectionTwo {
        ul {
          padding: 0;
          margin: 0;
          li {
            list-style-position: inside;

            &:first-child,
            &:last-child {
              span {
                font-weight: 500;
              }
            }
          }
        }
      }

      .jobDetailsContentSectionThree {
        // border: 1px solid #ededed;
        padding: 0.5rem;
        font-weight: 500;
        font-size: 14px;
      }

      .jobDetailsContentSectionFour {
        .box {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;

          .boxContent {
            position: relative;
            width: 100%;
            height: 100%;
            min-height: 111px;

            .boxHeading {
              font-weight: 600;
              margin-bottom: 4px;
              font-size: 13px;
              line-height: 15px;
            }

            .boxNumber {
              color: #003f5a;
              font-size: 24px;
              margin-bottom: 7px;
              font-weight: 600;
            }

            .boxDivider {
              margin: 0 auto;
              height: 3px;
              width: 25px;
              background: #0a7f8f;
              position: absolute;
              bottom: 11px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }

    .popupBg {
      background: linear-gradient(
        270deg,
        #efffff 0.16%,
        rgba(239, 255, 255, 0) 99.89%
      );
    }

    .popupBorder {
      border-bottom: 1px solid #e2e2e2e2;
      padding: 10px;
      padding-left: 4px;
      display: grid;
      grid-template-columns: 25px 150px auto;
      grid-column-gap: 5px;
      height: 45px;
      align-items: center;
    }

    .skillsText {
      border-radius: 6px;
      border: 1px solid #cecece;
      padding: 4px 8px 4px 8px;
      width: fit-content;
    }
  }
  .jobDescription {
    border: 1px solid #dadada;
    box-shadow: 0px 4px 4px rgba(194, 194, 194, 0.25);
    border-radius: 6px;
    // margin-top: 24px;
    padding: 24px;
  }

  .jobscheduleContentSectionThree {
    border: 1px solid #ededed;
    padding: 14px;
    font-weight: 500;
    font-size: 14px;
    background-color: white;
  }

  .box {
    border: 1px solid #ededed;
    font-weight: 500;
    font-size: 14px;
    height: 9rem;
  }
  .font_value_colour {
    color: #484848;
  }
}

.job_details_section .ant-tabs-nav {
  margin: 0px !important;
  position: relative;
}