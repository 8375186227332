.customTabs {
    .ant-tabs > .ant-tabs-nav {
        border-bottom: 1px solid #DEDEDE;
    }
}

.tab_container {
    .ant-tabs.ant-tabs-top {
        padding: 10px 30px;
    }
}