@import './variables';

// ---------- Starts :: For styling entire react hook form antd custom components ---------- 
.customForm{
  // .customLabel{
  //   display: block;
  //   padding-bottom: 8px;
  //   color: rgba(0, 0, 0, 0.88);
  //   font-size: 14px;
  // }
  .ant-form-item-label{
    padding-bottom: 0;
  }
  .ant-form-item{
    margin-bottom: 10px;
  }
}
// ---------- Ends :: For styling entire react hook form antd custom components ---------- 



// ---------- Starts :: For styling entire loading wrappers across application ---------- 
.errorWrapper{
  // h-96 flex items-center justify-center
  display: flex;
  justify-content: center;
  align-items: center;
  height : calc(100vh - 150px);
}
// ---------- Ends :: For styling entire loading wrappers across application ---------- 



// ---------- Starts :: For styling scroll bar if this class is given where ever needed ---------- 
.customScrollBar {
  position: relative;
  &::-webkit-scrollbar-track {
    background-color: #E7E5E5;
  }
  &::-webkit-scrollbar {
    background-color: #C1C1C1;
    border: 5px solid #e7e5e5;
    border-radius: 20px;
    width: 12px;
    height: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #C1C1C1;
    border: 5px solid #E7E5E5;
    border-radius: 20px;
  }
}
// mixin version of class to reuse this set of styles 
@mixin customScrollbar {
  position: relative;
  &::-webkit-scrollbar-track {
    background-color: #E7E5E5;
  }
  &::-webkit-scrollbar {
    background-color: #C1C1C1;
    border: 5px solid #e7e5e5;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #C1C1C1;
    border: 5px solid #E7E5E5;
    border-radius: 20px;
  }
}
// ---------- Ends :: For styling scroll bar if this class is given where ever needed ---------- 


// ---------- Starts :: For styling all input type file by default ends ---------- 
.uploadItem{
  input {
    margin-top: 20px;
    // border: 1px solid rgb(212, 212, 212);
    // border-radius: 4px;
    width: 100%;
    &::file-selector-button {
      background-color: transparent;
      border: none;
    }
  }
}
// ---------- Ends :: For styling all input type file by default ends ---------- 

// ---------- Starts :: for styling uls with abc ---------- 
.orderedAlphaList {
  counter-reset: alpha;
  li:before {
    counter-increment: alpha;
    content: "(" counter(alpha, lower-alpha) ") ";
  }
}
// ---------- Ends :: for styling uls with abc ---------- 


// ---------- Starts :: for styling availability slots across entire app ---------- 
.availabilitySlotsWrapper {
  input[type=checkbox]{
    display: none;
  }
  input[type=checkbox]:checked + .checkbox-alias{
    border: 2px solid #0A7F8F;
    color: #0A7F8F;
  }
  .checkbox-alias{
    border: #B4EDC8;
    background: #B4EDC8;
    color: #0A7F8F;
    border: 2px solid transparent;
  }
  .checkbox-alias[disabled]{
    $disabledGray : rgb(212, 212, 212);
    border: 2px solid rgb(212, 212, 212);
    background: $disabledGray;
    span{
      color: rgb(158, 158, 158);
    }
  }
}
// ---------- Ends :: for styling availability slots across entire app ---------- 



// ---------- Starts :: pulse animation style ---------- 

$background : #5a99d4;
$pulseDownRange : .4;
$pulseUpRange : .6;
$shadowRange : 20px;
.pulseButton {
  display: block;
  color: white;
  border: none;
  border-radius: 50%;
  background: $background;
  box-shadow: 0 0 0 0 rgba($background, .5);
  animation : pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform : scale($pulseDownRange);
  }
  70% 
  {
    transform : scale($pulseUpRange);
    box-shadow: 0 0 0 $shadowRange rgba($background, 0);
  }
  100% 
  {
    transform :scale($pulseDownRange);
    box-shadow: 0 0 0 0 rgba($background, 0);
  }
}
// ---------- Ends :: pulse animation style ---------- 

.loadingSpinner {
  .ant-spin-dot-item{
    background-color: white;
  }
}
