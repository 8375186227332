@import "../../Styles/variables";


.dateGridTable{
  .MuiDataGrid-cellContent{
    font-size: 14px;
    font-weight: normal;
    padding-left: 0px;
    font-family: 'Poppins';
  }
  &.new{
    .MuiDataGrid-columnHeaders{
      background: #E5F6F6;
      position: sticky;
      z-index: 51;
      top: 4.20rem;
    }
    .MuiDataGrid-root{
      border-right: none;
      border-left: none;
      border-bottom: none;
      box-shadow: none;
      border-radius: 0.5rem;
      .MuiDataGrid-toolbarContainer{
        background: #FFF;
        border-width: 1px;
        border-style: solid;
        border-color: rgba(224, 224, 224, 1);
        border-bottom: none;
        padding: 15px 2px;
        border-top: none;
        position: sticky;
        z-index: 50;
        top: 0;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
      }
      .MuiDataGrid-main{
        overflow: unset;  // needed for the headers to stick to the container's topside
        border-width: 1px;
        border-style: solid;
        border-color: rgba(224, 224, 224, 1);
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      .MuiDataGrid-footerContainer{
        border: none;
        margin-top: 30px;
      }
    }
  }

  .Mui-even-row{
    background: #FFF;
  }
  .Mui-odd-row{
    background: #F9FFFF;
  }
  
}
.dataGridCustomPagination{
  background-color: #FFF;
  border-radius: 20px;
  border: 1px solid #EEEEEE;
  box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.2);
  padding: 5px 15px;
  .Mui-selected{
    background-color: $secondaryMain!important;
  }
  li{
    &:first-child{
      margin-right: 13px;
      svg{
        width: 30px;
        height: auto;
        color: rgba(169, 169, 169, 0.73);;
      }
    }
    &:last-child{
      margin-left: 13px;
      svg{
        width: 30px;
        height: auto;
        color: rgba(169, 169, 169, 0.73);;
      }
    }
  }
}

.customPaginationOutsideWrapper{
  .dataGridCustomPagination{
    margin-left: 4px;
  }
  .rowsPerPage{
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  .pageCountData{
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
}

// for dark mode

html {
  &.dark {
    .dateGridTable{
      // .Mui-even-row{
        // background: red;
      // }
      .Mui-odd-row{
        background: rgba(68, 68, 68, 0.301);
      }
    }
  }
}