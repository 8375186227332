.main-sidebar {
  .yvi-logo {
    padding-inline: 0;
    min-width: 1.75rem;
  }
  .ant-menu-item {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .ant-menu-item-icon {
    color: #848484;
    font-size: 1.875rem;
  }
  .ant-menu-item:hover {
    background-color: #0a7f8f !important;
    .ant-menu-title-content {
      color: white;
    }
    .ant-menu-item-icon {
      color: white;
    }
  }
  .ant-menu-item-selected {
    background-color: #0a7f8f !important;
    .ant-menu-title-content {
      color: white;
    }
    .ant-menu-item-icon {
      color: white;
    }
  }
  & > .ant-tooltip-open {
    background-color: #0a7f8f;
  }
  .ant-menu-title-content {
    color: #848484;
    font-size: 1rem;
    font-weight: 500;
    padding-left: 0.5rem;
  }
}
