.radioGroup {
  &.withCustomContent {
    display: flex;
    overflow-x: auto;

    .radioItemsWrapper {
      margin-right: 24px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

}

