//Breakpoints taken from tailwind.config.js
$xs: 350px;
$tab: 980px;

.metricsAccordion {
  * {
    &.ant-collapse-content-box {
      padding: 0 !important;
    }

    &.ant-collapse-item {
      border-bottom: 0 !important;
    }
  }
}

.questionChoices {
  * {
    &.ant-radio-wrapper {
      width: 100%;
      border-radius: 0.35rem;

      @media screen and (min-width: $xs) {
        padding: 0.25rem;
      }

      @media screen and (min-width: $tab) {
        padding: 1rem;
      }

      &:hover {
        background: #f7f7f7;
      }

      &-checked {
        background: #f7f7f7;
        font-weight: 500 !important;
      }
    }
  }
}

.questionResponses {
  * {
    &.checkboxGroupItem {
      border-radius: 0.35rem;

      @media screen and (min-width: $xs) {
        padding: 0.25rem;
      }

      @media screen and (min-width: $tab) {
        padding: 1rem;
      }
      &:hover {
        background: #f7f7f7;
      }
    }
  }
}



.feedback_radio .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: black !important;
  background-color: white !important;
}

.feedback_radio .ant-radio-wrapper .ant-radio-inner::after {
  background-color: #11B2AB !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #11B2AB !important;
  border-color: #11B2AB !important;
}