/* 
  Global class names for usage across project
*/


/* 
  Class for styling divider
*/
.yviDivider {
  height: 1px;
  width: 100%;
  border: 1px solid #D1D1D1;;
}