.addJobMainDiv {
  // height: calc(100% - 30px);
  overflow: hidden;
  border: 1px solid;
  border-color: #d3e0e0;
  margin: auto;
  border-radius: 8px;
  padding-bottom: 20px;
  max-height: 90vh;
  overflow: auto;

  :global {
    .optionContainer.notFound {
      display: none;
      padding: 0;
    }

    .optionContainer .displayBlock {
      display: none;
    }

    .MuiStepIcon-root {
      //height: 30px;
      //width: 29px;
      color: #0c2556;
      border: 1px solid #0c2556;
      border-radius: 20px;
      padding: 1px;
    }
    .MuiStepConnector-line {
      display: block;
      border-color: #0c2556;
      border-top-style: solid;
      border-top-width: 3px;
    }
    .MuiStepIcon-root.Mui-active {
      color: #0c2556;
    }
    .MuiStepIcon-root.Mui-completed {
      color: #0c2556;
    }

    .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
      margin-top: 3px;
    }

    .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
      margin: 4px 0;
    }

    .stepper_accordion_div {
      width: 98%;
      margin: auto;
      // margin-top: 15px;
      overflow: auto;
      height: calc(100vh - 90px);
    }

    .MuiAccordion-root {
      box-shadow: none;
      //margin-top: 10px;
    }
    .MuiAccordion-root::before {
      background-color: white;
    }
    // .MuiAccordionSummary-root {
    //   //background-color: #0c2556;
    //   //color: #fff;
    //   border-radius: 15px;
    // }

    .accordion_details {
      width: 100%;
      height: 100%;
      background-color: #fff;
    }

    .addJobInfo_maindiv {
      height: 100%;
      width: 100%;
      // background-color: lightgray;
      border-radius: 10px;
    }

    .MuiAccordionDetails-root {
      padding: 0;
    }

    // .MuiAccordionSummary-root.Mui-expanded{
    //    border-bottom-left-radius: 0px;
    //    border-bottom-right-radius: 0px;
    // }

    .addInfoMainDiv {
      // background-color: lightgray;
      background-color: #d3d3d35e;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      padding: 25px;
    }

    .basic_Info select {
      width: 100%;
      height: 40px;
      // margin: 12px;
      border-radius: 4px;
      outline: none;
      border: 0;
      background-color: #fff;
      // box-shadow: 0px -3px 3px rgba(14, 13, 13, 0.08);
      font-size: 14px;
      font-weight: 400;
      text-align: start;
      padding-left: 5px;
      color: gray;
      border: 0.1px solid rgba(0, 0, 0, 0.35);
      //box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .other_info select {
      width: 100%;
      height: 40px;
      // margin: 12px;
      border-radius: 4px;
      outline: none;
      border: 0;
      background-color: #fff;
      // box-shadow: 0px -3px 3px rgba(14, 13, 13, 0.08);
      font-size: 14px;
      font-weight: 400;
      text-align: start;
      padding-left: 5px;
      color: gray;
      border: 0.1px solid rgba(0, 0, 0, 0.35);
    }

    // .schedule span {
    //   width: 100%;
    //   text-align: start;
    // }

    select:required:invalid {
      color: gray;
    }
    option[value=''][disabled] {
    }
    option {
      color: black;
    }

    .basicInfo_inputDiv {
      width: 100%;
      height: 15%;
    }

    .basicInfo_inputDiv input {
      width: 80%;
      // padding: 7px;
      height: 40px;
      border-radius: 10px;
      outline: none;
      border: 0;
      background-color: #fff;
      font-size: 14px;
      font-weight: 400;
      text-align: start;
      padding-left: 5px;
      border: 0.1px solid rgba(0, 0, 0, 0.35);
      text-transform: capitalize;
      // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .basicinfo_description h5 {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.88);
      font-size: 14px;
      font-family: Poppins;
      font-style: normal;
    }

    .basicinfo_description input {
      width: 82%;
      height: 40px;
      margin: 12px;
      border-radius: 8px;
      outline: none;
      border: 0;
      background-color: #fff;
      // box-shadow: 0px -3px 3px rgba(14, 13, 13, 0.08);
      font-size: 14px;
      font-weight: 400;
      text-align: start;
      padding-left: 5px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .no_of_notice {
      border-radius: 8px;
      outline: none;
      border: 0;
      background-color: #fff;
      box-shadow: 0px -3px 3px rgba(14, 13, 13, 0.08);
      font-size: 14px;
      font-weight: 400;
      text-align: start;
      padding-left: 5px;
      height: 35px;
      width: 0;
      margin: 12px;
      max-width: 82%;
      min-width: 82%;
    }

    .title_reference {
      display: flex;
    }

    .basicInfo_title {
      flex-grow: 1;
      display: grid;
    }

    .basicInfo_title_input {
      width: 100%;
      // padding: 7px;
      height: 40px;
      border-radius: 4px;
      outline: none;
      border: 0;
      background-color: #fff;
      font-size: 14px;
      font-weight: 400;
      text-align: start;
      padding-left: 5px;
      border: 0.1px solid rgba(0, 0, 0, 0.35);
      text-transform: capitalize;
      // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .basicinfo_reference {
      flex-grow: 1;
      display: grid;
      grid-template-columns: 100%;
      justify-items: end;
    }

    .basicinfo_description {
      width: 100%;
      height: 20%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      grid-template-columns: 100%;
      grid-column: 1/-1;
    }

    .basicInfo_dropdown_div {
      width: 100%;
      height: auto;
      display: grid;
      grid-template-columns: 48% 48%;
      grid-column-gap: 4%;
      grid-row-gap: 10px;
      margin-top: 10px;
    }

    .dropdowndiv_one {
      flex-grow: 1;
      display: grid;
    }

    .dropdowndiv_empty {
      flex-grow: 1;
    }

    .dropdowndiv_two {
      flex-grow: 1;
      display: grid;
      grid-template-columns: 100%;
      justify-items: end;
    }

    .hiringTeam {
      width: 100%;
      // background: #d3d3d35e;
      height: auto;
      border-radius: 10px;
      padding: 10px;
      display: flex;
      flex-wrap: wrap;
      // grid-template-columns: 50% 49%;
      // grid-template-columns: auto auto;
      // grid-column-gap: 10px;
      gap: 20px;
    }
    .hiringTeam > div {
      flex-basis: 47%;
    }

    .publish_layout {
      width: 98.5%;
      /* background: rgba(211, 211, 211, 0.368627451); */
      height: auto;
      border-radius: 10px;
      padding: 10px;
      margin: 10px;
      border: 1px solid #f3f3f3;
      box-shadow: 0px 4px 4px rgba(194, 194, 194, 0.25);
      border-radius: 6px;
    }

    .hiringTeamLeftDiv {
      box-shadow: 0px 4px 4px rgba(194, 194, 194, 0.25);
    }

    .hiringTeamRightDiv {
      box-shadow: 0px 4px 4px rgba(194, 194, 194, 0.25);
    }

    .hiringTeam h3 {
      text-align: left;
      font-size: 14px;
      font-weight: bold;
    }

    .card_maindiv {
      display: flex;
      margin-bottom: 20px;
      margin-top: 10px;
    }

    .card_maindiv p {
      text-align: left;
      padding-left: 10px;
      font-size: 14px;
      // font-weight: 600;
      font-family: sans-serif;
      padding: 8px;
      margin: 0;
    }

    .select_card {
      width: 23%;
      background-color: white;
      // border-radius: 10px;
      height: 200px;
      margin: 5px;
      margin: auto;
      border-radius: 10px !important;
      // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
      border: 0.2px solid #8a8585;
    }
    .select_card span {
      float: right;
      padding-right: 15px;
      cursor: pointer;
    }

    .profile_img {
      margin: auto;
      height: 80px;
      width: 85px;
    }

    .cancel {
      // outline: none;
      // background: none;
      // border: none;
      font-size: 12px;
      font-family: sans-serif;
      color: black;
      height: 42px;
      border-radius: 4px;
    }

    .save {
      // padding: 10px;
      width: auto;
      background: #1C4980;
      border-radius: 4px;
      color: white;
      border: none;
      font-size: 12px;
      font-family: sans-serif;
      font-weight: 600;
      height: 42px;
      padding: 8px 16px;
    }

    .test {
      margin-top: -16px;
      margin-left: 75px;
      width: 120px;
      background-color: #9890908c;
      height: 35px;
      position: absolute;
      z-index: 2;
      border: none;
      font-size: 14px;
      cursor: pointer;
    }

    .css-7gimdr-MuiButtonBase-root-MuiAccordionSummary-root {
      max-height: 45px;
      min-height: 0px;
    }
    .css-7gimdr-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
      min-height: 0px;
    }

    .css-o4b71y-MuiAccordionSummary-content {
      margin: 8px 0;
    }

    .MuiButtonBase-root :hover {
      // color: black;
      cursor: pointer;
    }

    .addCardHeader {
      top: 0;
      position: absolute;
      width: 100%;
      background: gray;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .cardList {
      left: 0;
      position: absolute;
      font-size: 14px;
      font-family: sans-serif;
    }

    .noteHeader {
      top: 0;
      position: absolute;
      width: 100%;
    }

    .noteBottomLayout {
      // bottom: 0;
      // position: absolute;
      // width: 100%;
      height: 10%;
    }

    .noteBottomLayout input {
      border: none;
      width: 75%;
      padding: 8px;
      float: right;
      background-color: #e1d9d924;
      outline: none;
    }

    .select_card select {
      width: 100%;
      padding: 8px;
      outline: none;
      border: none;
      background-color: white;
      height: 35px;
      border: none;
      font-size: 14px;
      cursor: pointer;
    }

    .sourcing {
      flex-grow: 1;
      height: 160px;
      margin: auto;
      text-align: initial;
    }

    .prescreening {
      // flex-grow: 1;
      border-radius: 10px;
      height: 190px;
      margin: 10px;
      margin: auto;
      width: 30%;
    }

    .interviews {
      // flex-grow: 1;
      border-radius: 10px;
      height: 190px;
      margin: auto;
      width: 70%;
    }

    .alice-carousel {
      width: fit-content;
    }

    //     .alice-carousel__stage{
    //       display: flex;

    //     }
    .alice-carousel__stage-item {
      width: fit-content;
    }

    h5 {
      margin: 4px;
      padding: 0;
      //padding-left: 5px;
      text-align: left;
      font-size: 12px;
      font-weight: 600;
      font-family: sans-serif;
    }

    .hiringTeam li {
      list-style-type: none;
    }

    .ant-menu-light .ant-menu-submenu-selected .ant-menu-submenu-title {
      color: black;
    }

    .ant-menu-light.ant-menu-horizontal.ant-menu-submenu-selected ::after {
      border-bottom-color: transparent;
      border-bottom-width: 0px;
    }

    .note_icons {
      font-size: small;
      color: gray;
      cursor: pointer;
    }

    // .hiringTeam ul {
    //   padding: 0;
    //   padding-left: 20px;
    // }

    .prescreening_maindiv {
      display: flex;
    }

    .prescreening_card {
      width: 80%;
      background-color: white;
      border-radius: 10px;
      height: 160px;
      margin: 5px;
      border: 0.2px solid #8a8585;
    }

    .prescreening_card_option {
      width: 35%;
      background-color: white;
      border-radius: 10px;
      height: 160px;
      margin: 5px;
      // border: 0.2px solid #8a8585;
      height: 160px;
    }

    .publishing_div {
      display: flex;
    }

    .publishing_div div {
      text-align: left;
    }

    .publish_header p {
      text-align: left;
      padding-left: 8px;
      font-size: 14px;
      margin: 0;
      margin-bottom: 30px;
      width: 45%;
    }

    .publishing_div p {
      // margin: 0;
      padding: 0;
      text-align: left;
      padding-left: 25px;
      font-size: 14px;
      width: 85%;
    }

    .publishNoe_icon {
      display: flex;
      font-size: 14px;
      font-weight: 600;
      font-family: inherit;
    }

    .sourcing li {
      display: flex;
    }

    // new

    .schedule select {
      width: 82%;
      height: 40px;
      margin: 12px;
      border-radius: 8px;
      outline: none;
      border: 0;
      background-color: #fff;
      // box-shadow: 0px -3px 3px rgba(14, 13, 13, 0.08);
      font-size: 14px;
      font-weight: 400;
      text-align: start;
      padding-left: 5px;
      color: gray;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .amount_input {
      width: 82%;
      height: 40px;
      margin: 12px;
      border-radius: 8px;
      outline: none;
      border: 0;
      background-color: #fff;
      // box-shadow: 0px -3px 3px rgba(14, 13, 13, 0.08);
      font-size: 14px;
      font-weight: 400;
      text-align: start;
      padding-left: 5px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .dropdowndiv_one textarea {
      width: 82%;
      margin: 12px;
      border-radius: 8px;
      outline: none;
      border: 0;
      background-color: #fff;
      // box-shadow: 0px -3px 3px rgba(14, 13, 13, 0.08);
      font-size: 14px;
      font-weight: 400;
      text-align: start;
      padding-left: 5px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .schedule ul {
      display: flex;
      list-style: none;
      padding: 5px;
      margin: 5px;
    }

    .schedule li {
      margin-right: 5px;
      flex-grow: 1;
      flex-direction: column;
    }

    #visable {
      display: none;
    }

    .level_mainDiv {
      width: 100%;
      height: 106%;
      display: flex;
      // background-color: #8a8585;
      display: grid;
      grid-template-columns: auto auto auto;
      grid-column-gap: 2px;
      grid-row-gap: 4px;
      overflow: auto;
      padding-left: 16px;
    }

    .level_insidecard {
      //  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      //  height: f;
      //  border-left: 1px solid black;
      //  border-right: 1px solid black;
      // margin: 2px;
      border: 0.1px solid rgba(0, 0, 0, 0.35);
      width: 170px;
      height: 130px;
      color: white;
      border-radius: 10px;
      background-color: #fff;
      color: black;
      padding: 4px;
      // overflow: auto;
    }

    .levelHeader {
      height: 15px;
    }

    .level_insidecard p {
      width: 99%;
      padding: 0;
      margin: 0;
    }

    .level_content {
      height: 100px;
      overflow: auto;
      padding: 4px;
      margin-top: 2px;
    }
    .level_content ::-webkit-scrollbar {
      display: none;
    }

    .slick-track div {
      width: 99%;
    }

    .slick-slide.slick-active.slick-current {
      width: 99%;
    }

    .all_Level {
      font-size: 12px;
      color: #0c2556;
      font-family: sans-serif;
      font-weight: bold;
      text-decoration: underline;
    }

    .level_insidecard {
      //  font-size: 12px;
    }

    .level_insidecard h5 {
      // font-weight: 600;
      font-size: 14px;
    }

    .level_header {
      width: 100%;
      background-color: #9e9494;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .level_div {
      background-color: white;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
    }

    // .level_div :hover {
    //   background-color: rgb(232, 48, 48);
    //   position: relative;
    //   z-index: 1;
    //   border-radius: 10px;
    //   width: 100%;
    // }

    .data_time_div {
      width: 30%;
    }

    .data_time_div span {
      padding-left: 10px;
      font-weight: 600;
      font-size: 14px;
    }

    .data_time {
      width: 100%;
      padding: 8px;
      border-radius: 8px;
      margin-left: 10px;
      border: 1px solid #d9d9d9;
      margin-top: 7px;
      outline: #0a7f8f;
    }

    .data_time :focus {
      border-color: #0a7f8f;
    }

    // .left_Header {
    //   padding-left: 10px;
    // }

    .right_header {
      margin-left: 16%;
      margin-right: auto;
    }

    .sourcing ul {
      list-style-type: none;
    }

    .add_icon {
      margin-top: auto;
      color: #0c2556;
      font-size: xxx-large;
      margin-right: 10px;
      position: relative;
      z-index: 1;
      position: absolute;
      right: 125px;
      bottom: 25%;
    }

    .add_plus__icon {
      font-size: xxx-large;
      float: right;
      margin-right: -10px;
      color: #0c2556;
      cursor: pointer;
    }

    .add_Hiringstage_icon {
      margin-top: auto;
      color: #0c2556;
      font-size: xxx-large;
      margin-right: 10px;
      position: relative;
      z-index: 1;
      position: absolute;
      right: 20px;
      bottom: 12%;
    }

    .add_reviwer_icon {
      margin-top: auto;
      color: #0c2556;
      font-size: xxx-large;
      margin-right: 10px;
      position: relative;
      z-index: 1;
      position: absolute;
      right: 21px;
      bottom: 46%;
    }

    // hiring  Stage

    .hiringStage {
      width: 100%;
      height: auto;
      border-radius: 8px;
      padding: 10px;
      // display: flex;
      // gap: 20px;
      // padding-bottom: 5%;
      display: grid;
      grid-template-columns: 50% 50%;
    }

    .hiringStage h3 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
    }

    .hiringStage ul {
      text-align: left;
    }

    .level_list {
      width: 100%;
      height: auto;
      background: #f7f7f7;
      border-radius: 4px;
    }

    .level_heading {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;

      color: #0a7f8f;
    }

    .level_leftHeading {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #000000;
    }

    .level_rightContent {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #656565;
    }

    .hiringSatageLeftDiv {
      box-sizing: border-box;
      width: 100%;
      height: auto;
      background: #ffffff;
      border: 1px solid #f3f3f3;
      box-shadow: 0px 4px 4px rgba(194, 194, 194, 0.25);
      border-radius: 6px;
    }

    .hiringStageRightDiv {
      box-sizing: border-box;
      width: -webkit-fill-available;
      height: auto;
      background: #ffffff;
      border: 1px solid #f3f3f3;
      box-shadow: 0px 4px 4px rgba(194, 194, 194, 0.25);
      border-radius: 6px;
    }

    .number_span {
      padding: 2px;
      border-radius: 50%;
      text-align: center;
      padding-left: 5px;
      padding-right: 5px;
    }

    .details_table {
      // width: 100%;
      margin: auto;
    }
    // .details_table td:nth-child(odd)
    // {
    //   text-align: end;
    // }
    // .details_table td:nth-child(even)
    // {
    //   text-align: center;

    // }

    .details_table label {
      padding-left: 10px;
      font-size: 14px;
      font-family: sans-serif;
    }

    a {
      color: black;
    }

    .css-84mkcn-MuiPaper-root-MuiCard-root {
      border-radius: 10px !important;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
    }

    .popupWithNoClick {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      background-color: rgba(0, 0, 0, 0.35);
    }

    .popDialog {
      display: block;
      left: 55%;
      transform: translate(-23%, -10%);
      position: fixed;
      right: 0px;
      padding: 5px;
      margin-top: 10%;
      background-color: #fff;
      border: 1px solid black;
      z-index: 1;
      width: 300px;
      font-size: 12px;
      top: 5%;
    }

    .option_layout {
      display: grid;
    }

    .charField {
      width: 100%;
      border-radius: 6px;
      outline: none;
      background-color: #fff;
      font-size: 14px;
      font-weight: 400;
      text-align: start;
      padding-left: 5px;
    }

    .param_div {
      height: 60%;
      display: grid;
      grid-template-columns: auto auto auto auto;
      grid-column-gap: 5px;
      grid-row-gap: 5px;
      padding: 3px;
      overflow: auto;
    }

    .optional_param {
      height: 70%;
      display: grid;
      grid-template-columns: auto auto;
      overflow: auto;
      grid-column-gap: 5px;
      grid-row-gap: 5px;
    }

    .param {
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      padding: 1px;
      margin-top: 5px;
      margin-left: 5px;
      border-radius: 10px;
      width: fit-content;
      height: fit-content;
    }

    .close_icon {
      color: #0c2556;
      cursor: pointer;
      font-size: large;
    }

    .accordian_header {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }

    .par {
      margin-top: 10px;
      text-transform: none;
    }

    .jobs_header {
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #0a7f8f;
    }

    .jobs_sub_header {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }

    .auto_generate_id {
      float: right;
      color: #fff;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      background-color: #fb906e;
      width: 149px;
      height: 24px;
      border-radius: 150px;
      cursor: pointer;
      display: flex;
      padding: 4px;
      padding-left: 10px;
    }

    .addReviewerMainDiv .ant-form-item {
      margin-bottom: 0 !important;
    }

    // .ant-select-selector{
    //   height: 40px;
    // }

    .ant-tabs-content-holder {
      height: calc(100vh - 210px);
      overflow: auto;
    }

    .tab_container .ant-tabs-content-holder {
      height: calc(90vh - 210px);
      overflow: auto;
      display: block !important;
    }

    .time_input {
      width: 100%;
      /* margin: 10px; */
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      padding: 3px;
      outline: 0;
    }

    .time_input :hover {
      color: 1px solid #0a7f8f;
    }

    .time_input :focus {
      border-color: 1px solid #0a7f8f;
    }

    @media screen and (min-width: 500px) and (max-width: 1600px) {
      // .left_Header {
      //   font-size: 12px;
      // }

      // .right_header {
      //   font-size: 12px;
      // }

      .css-1f3hcpy-MuiTypography-root {
        font-size: 12px;
      }

      .basicInfo_inputDiv input {
        //height: 30px;
      }

      // .basic_Info select {
      //   height: 30px;
      // }

      .publish_maindiv {
        font-size: 12px;
        padding-top: 10px;
      }

      .amount_input {
        height: 30px;
      }
      // .other_info select {
      //   height: 30px;
      // }

      .select_card select {
        font-size: 12px;
      }

      .select_card {
        font-size: 12px;
      }
    }

    @media screen and (min-width: 0px) and (max-width: 650px) {
      .left_Header {
        font-size: 12px;
      }

      .right_header {
        font-size: 12px;
      }

      .card_maindiv {
        flex-direction: column;
      }

      .select_card {
        width: 100%;
        margin-top: 10px;
      }
      .jobs_viewmore_maindiv a {
        color: black;
        font-family: sans-serif;
        font-weight: 300;
      }
    }
    @media screen and (max-width: 700px) {
      .card_maindiv {
        flex-direction: column;
      }
      .interviews {
        margin-top: 65px;
      }
      .prescreening_card_option {
        width: 45%;
      }
    }
  }
}
