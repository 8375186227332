.dashBoard {
  .boxDetails {
    margin-top: 34px;

    .boxs {
      // text-align: center;
      // display: flex;
      // align-items: center;
      // justify-content: center;

      .boxContents {
        padding-top: 40px;
        padding-left: 40px;
        padding-bottom: 35px;
        position: relative;
        width: 100%;
        height: 85%;
        min-height: 150px;

        .heading {
          font-weight: 500;
          margin-bottom: 4px;
          font-size: 22px;
          line-height: 20px;
        }

        .number {
          color: #003f5a;
          font-size: 40px;
          margin-top: 21px;
          font-weight: 700;
        }
        .heading1 {
          //font-size: 14px;
          margin-bottom: 4px;
          font-weight: 500;
          line-height: 17px;
        }

        .number1 {
          color: #003f5a;
          font-size: 30px;
          margin-top: 21px;
          font-weight: 700;
          line-height: normal;
        }
        &.activeJobs {
          border-radius: 4px;
          border: 1px solid #d3d3d3;
        }

        &.pendingApproval {
          border-radius: 4px;
          border: 1px solid #d3d3d3;
        }

        &.jobsAwaiting {
          border-radius: 4px;
          border: 1px solid #d3d3d3;
        }

        &.resumes {
          border-radius: 4px;
          border: 1px solid #d3d3d3;
        }

        &.pipeline {
          border-radius: 4px;
          border: 1px solid #d3d3d3;
        }

        &.offered {
          border-radius: 4px;
          border: 1px solid #d3d3d3;
        }
      }

      .boxContents2 {
        padding-top: 40px;
        padding-left: 40px;
        padding-bottom: 45px;
        padding-right: 15px;
        position: relative;
        width: 100%;
        height: 80%;

        .heading2 {
          font-weight: 500;
          margin-bottom: 4px;
          font-size: 14px;
          line-height: 14px;
        }

        .number2 {
          color: #003f5a;
          font-size: 26px;
          margin-top: 21px;
          font-weight: 700;
        }

        &.activeJobs {
          border-radius: 4px;
          border: 1px solid #d3d3d3;
        }

        &.pendingApproval {
          border-radius: 4px;
          border: 1px solid #d3d3d3;
        }

        &.jobsAwaiting {
          border-radius: 4px;
          border: 1px solid #d3d3d3;
        }

        &.resumes {
          border-radius: 4px;
          border: 1px solid #d3d3d3;
        }

        &.pipeline {
          border-radius: 4px;
          border: 1px solid #d3d3d3;
        }

        &.offered {
          border-radius: 4px;
          border: 1px solid #d3d3d3;
        }
      }
    }
  }

  .circle-avatar {
    width: 29px;
    height: 29px;
    flex-shrink: 0;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .multi-recharts {
    .recharts-default-legend {
      li {
        padding-left: 0px;
      }
    }
  }

  .bar-recharts {
    .recharts-default-legend {
      li {
        padding-top: 30px;
        padding-left: 0px;
      }
    }
  }

  .yaxis-labelcustomisation {
    .recharts-cartesian-axis-tick {
      font-size: 12px;
      font-family: Poppins;
      padding-top: 30px;
    }
  }
}

.recharts-layer.recharts-cartesian-axis-tick text {
  font-size: 12px !important;
  word-break: break-all !important;
  @media only screen and (max-width: 1420px) {
    font-size: 8px !important;
  }
}

.infinite-scroll-component {
  overflow: hidden !important;
}
.ant-list-split .ant-list-item {
  // border-block-end: 1px solid rgba(5, 5, 5, 0.06);
  border-block-end: none;
}
.ant-drawer .ant-drawer-header {
  border-bottom: none !important;
}
// .ant-drawer-mask {
//   background: transparent !important; /* Remove background blur or opacity */
// }
.ant-drawer-content-wrapper {
  margin: 24px !important;
}
.ant-drawer .ant-drawer-body {
  padding: 6px !important;
}
.ant-drawer-content {
  border-radius: 4px !important;
  border: 0.5px solid var(--Border-yvi, #d7d7d7) !important;
  box-shadow: 0px 3px 28px 0px rgba(0, 0, 0, 0.06) !important;
}
.dashboard-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.resizable {
  resize: both; /* Allow both horizontal and vertical resizing */
  overflow: auto; /* Ensure content is scrollable if it exceeds bounds */
  min-width: 100px; /* Set a minimum width */
  min-height: 100px; /* Set a minimum height */
  position: relative; /* Needed for positioning the resize handle */
}

.resize-handle {
  width: 10px; /* Width of the resize handle */
  height: 10px; /* Height of the resize handle */
  background: #007bff; /* Color of the resize handle */
  position: absolute;
  bottom: 0;
  right: 0; /* Position it at the bottom-right corner */
  cursor: nwse-resize; /* Cursor style when hovering over the handle */
}
.resizable-card {
  resize: horizontal;
  overflow: hidden;
  min-width: 100px;
  max-width: 100%;
  padding: 1rem;
  background-color: #ffffff;
  border: 0.5px solid #d7d7d7;
  border-radius: 8px;
  cursor: pointer;
  transition: width 0.3s;
}

.icon-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-wrapper {
  flex: 3;
  padding-left: 0.5rem;
}

.delete-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background: transparent;
  border: none;
  cursor: pointer;
}
