.VideoInterview {
  width: 100%;
  height: 100vh;
  background: #f0f7ff;
  // web
  overflow: auto;

  #first_screen {
    width: 100%;
    height: 100%;
  }

  .first_screen_maindiv {
    display: grid;
    width: 65%;
    margin: auto;
    grid-template-columns: 40% 40%;
    /* align-items: center; */
    padding: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 70%;
    column-gap: 60px;
  }

  .logo_div {
    width: 100%;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid #efefef;
    background: linear-gradient(138deg, #fff 0%, #fff 100%);
    box-shadow: 0px 5px 16px 0px rgba(199, 199, 199, 0.22);
    align-items: center;
  }

  .img_div {
    width: 100%;
    /* height: 309px; */
    flex-shrink: 0;
    margin: auto;
  }

  .img_div img {
    object-fit: contain;
    width: 100%;
  }

  .logodiv_box {
    width: 90%;
    flex-shrink: 0;
    border-radius: 6px;
    border: 0.5px dashed #c0c0c0;
    background: linear-gradient(
      1deg,
      #e3fffc 0%,
      rgba(246, 255, 254, 0) 100%
    );
    box-shadow: 1px 5px 10px 0px rgba(214, 214, 214, 0.25);
    margin: auto;
    text-align: center;
    padding: 10px;
  }

  .original_logo_div {
    width: 100%;
    margin: auto;
    align-items: center;
    text-align: center;
    padding: 20px;
  }

  .go_to_interview {
    width: 100%;
    height: 48px;
    flex-shrink: 0;
    border-radius: 110px;
    background: linear-gradient(
      108deg,
      #0dbe81 0%,
      rgba(0, 63, 41, 0.61) 59.36%,
      rgba(15, 195, 133, 0) 100%
    );
  }

  #test_screen {
    width: 100%;
    height: 100%;
  }

  // mobile

  .mobile_firstscreen {
    height: 100%;
    width: 100%;
    // background: #f0f7ff;
    display: flex;
    flex-direction: column;
  }

  .mobile_logodiv {
    padding: 10px;
  }

  .mobile_logo_mindiv {
    // height: 60vh;
    min-height: 60vh;
    display: grid;
    justify-items: center;
  }

  .mobile_details {
    width: 100%;
    // height: 40vh;
    min-height: 40vh;
    flex-shrink: 0;
    border-radius: 30px 30px 0px 0px;
    background: #fff;
    bottom: 0;
    position: absolute;
  }

  // .mobile_logodiv {
  //   // width: 20%;
  //   // margin: auto;
  //   // align-items: center;
  //   padding-top: 35px;
  // }

  .mentalkingImg {
    width: 60%;
    margin: auto;
    padding-top: 40px;
  }

  .mobile_interviewDetails {
    display: grid;
    grid-template-columns: 75% 25%;
    // height: 70%;
    height: 30vh;
  }

  .mobile_interviewDetails h2 {
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    text-transform: capitalize;
  }

  .go_to_interview_mobile {
    width: 100%;
    // height: 47px;
    // height: 42px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #00b165;
    margin: auto;
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px;
  }

  .go_to_interview_mobile_disabled {
    width: 100%;
    // height: 47px;
    // height: 42px;
    flex-shrink: 0;
    border-radius: 10px;
    background: gray;
    margin: auto;
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px;
  }

  .web_screen_button {
    height: 47px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #00b165;
    margin: auto;
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0px 30px;
    // padding-left: 18px;
    // padding-right: 18px;
  }

  .web_screen_button_new {
    background: #11B2AB;
    border-radius: 20px;
  }

  .web_screen_button_disabled {
    height: 47px;
    flex-shrink: 0;
    border-radius: 20px;
    background: gray;
    margin: auto;
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0px 30px;
    // padding-left: 18px;
    // padding-right: 18px;
  }

  .go_to_interview_mobile :disabled {
    background: #e0e0e0 !important;
  }

  .left_header {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  .right_header {
    color: #454545;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }

  .mobile_bottom_div {
    bottom: 0;
    position: absolute;
    width: 100%;
  }

  .web_bottom_div {
    bottom: 0;
    // position: absolute;
    width: 100%;
  }

  // .logo_header {
  //   height: 55px;
  //   border-bottom: 1px solid #676666;
  // }

  .logo_header img {
    height: 55px;
  }

  .guideline {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 10px;
  }

  .agree {
    color: #525252;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .alice-carousel {
    // height: 100%;
  }

  .alice-carousel div {
    // height: 100%;
    text-align: -webkit-center;
  }

  .alice-carousel__stage {
    height: 54vh;
  }

  .alice-carousel__dots {
    margin: 0;
  }

  .alice-carousel__dots .alice-carousel__dots-item {
    margin-right: 8px;
  }

  .agree_div {
    height: 10%;
  }

  .agree_div p {
    text-align: center;
    padding: 3px;
  }

  .carousel_div {
    // height: 70%;
  }

  .video_guidline {
    // height: 100%;
  }

  .guideline_p {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    // line-height: 28px;
  }

  .guidline_img {
    object-fit: contain;
    height: 32vh;
  }

  .mobile_test_preview {
    height: 63vh;
  }

  .mobile_test_details {
    width: 100%;
    // height: 50vh;
    flex-shrink: 0;
    border-radius: 30px 30px 0px 0px;
    background: #fff;
    bottom: 0;
    position: absolute;
    min-height: 49vh;
  }

  .test_box {
    width: 87%;
    height: 38vh;
    flex-shrink: 0;
    overflow-y: auto;
    border-radius: 10px;
    background: #f0f6ff;
    margin: auto;
    margin-top: 13.906px;
    padding-bottom: 1rem;
  }

  .test_box ul {
    width: 90%;
    margin: auto;
  }

  .test_list {
    padding-top: 10px;
    padding-bottom: 10px;
    flex-shrink: 0;
    border-bottom: 0.2px #7e7e7e;
    border-style: dashed;
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    display: flex;
  }

  // .test_list span {
  //   float: right;
  // }

  .camera_preview_mobile {
    height: calc(100% - 58px);

    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg); // Safari and Chrome
    -moz-transform: rotateY(180deg); // Firefox
  }

  .camera_preview {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg); // Safari and Chrome
    -moz-transform: rotateY(180deg); // Firefox
  }

  .mobile_test_details span {
    float: right;
  }

  .timeCounterDiv {
    width: 100%;
    //height: 50vh;
    flex-shrink: 0;
    border-radius: 30px 30px 0px 0px;
    bottom: 0;
    position: absolute;
    background: #7c56e9;
    color: white;
    display: grid;
    justify-items: center;
    padding: 10px;
    min-height: 45vh;
  }

  .timeCounterDiv h3 {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 28px;
    font-style: italic;
    font-weight: 900;
    line-height: 36px; /* 128.571% */
    text-transform: capitalize;
    padding-top: 32px;
  }

  .item_text_div {
    padding-left: 50px;
    padding-right: 50px;
  }

  .go_to_interview_mobile :disabled {
    background-color: #454545 !important;
  }

  #video {
    height: 100%;
    object-fit: cover;
    width: 100%;
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg); /* Safari and Chrome */
    -moz-transform: rotateY(180deg); /* Firefox */
  }

  .top_img {
    position: absolute;
    top: 52%;
  }

  .timeCounterDiv h4 {
    font-size: 16px;
    font-weight: 500;
    font-family: Poppins;
  }

  // .centered {
  //   position: absolute;
  //   top: 67%;
  // }

  .start_count_text {
    position: absolute;
    top: 65%;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 70px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 51.429% */
    text-transform: capitalize;
  }

  .question_main_divb {
    width: 90%;
    margin: auto;
    // height: 100%;
    // overflow-y: scroll;
  }

  .Quiestion_video_box {
    width: 100%;
    display: grid;
    grid-template-columns: 60% 37%;
    column-gap: 10px;
    margin-top: 10px;
  }

  .question_box {
    width: 100%;
    height: 150px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #fff;
    padding: 15px;
  }

  .video_box {
    display: grid;
    row-gap: 10px;
  }

  .video_preview {
    height: 98px;
  }

  .timer {
    width: 100%;
    height: 31px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #7c56e9;
    text-align: center;
    line-height: 2.2;
  }

  .timer span {
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .display_Question {
    width: 100%;
    height: 50vh;
    flex-shrink: 0;
    border-radius: 10px;
    background: #fff;
    padding: 15px;
    margin-top: 20px;
  }

  .display_Question h3 {
    color: #000;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 10px;
  }
  .display_Question p {
    padding: 30px;
  }

  .completed {
    color: #00b165;
    text-align: center;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 29px; /* 131.818% */
    text-transform: uppercase;
    padding: 12px;
  }

  .completed_p {
    color: #868686;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    padding-left: 14px;
    padding-right: 14px;
  }

  .web_first_screen {
    width: 25%;
    height: 100%;
    margin: auto;
    padding-top: 45px;
  }

  .web_details {
    width: 100%;
    // height: 40vh;
    // min-height: 40vh;
    flex-shrink: 0;
    border-radius: 30px 30px 30px 30px;
    background: #fff;
    bottom: 0;
    // position: absolute;
  }

  .web_logo_mindiv {
    // height: 60vh;
    // min-height: 60vh;
    display: grid;
    justify-items: center;
    height: 35vh;
  }

  .mobile_logodiv img {
    height: 40px;
  }

  .web_first_screen_box {
    border-radius: 30px;
    border: 1px solid #e7e7e7;
    background: #f0f6ff;
  }

  .web_main_header {
    padding: 15px;
    border-bottom: 1px solid #676666;
    padding-left: 30px;
  }

  .web_main_header img {
    height: 45px;
  }

  .guildLine_mindiv {
    padding: 30px;
  }

  .guildLine_mindiv h3 {
    color: #424242;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .guildLine_img_div {
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding-top: 10px;
    column-gap: 15px;
    height: calc(100% - 30px);
  }

  .guidline_card {
    flex-shrink: 0;
    border-radius: 10px;
    background: #f6faff;
    height: 55vh;
  }

  .g_img_div {
    //height: 300px;
    height: 44%;
  }

  .g_img_div img {
    height: 100%;
    width: 100%;
  }

  .guidline_text {
    flex-shrink: 0;
    border-radius: 10px;
    background: #296972;
    width: 100%;
    //height: 193px;
    height: 56%;
    flex-shrink: 0;
    text-align: center;
  }

  .guidline_text h3 {
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding: 25px;
  }

  .checkbox_div {
    padding: 30px;
  }

  .testscreen_main_div {
    display: grid;
    grid-template-columns: 45% 44%;
    column-gap: 60px;
    width: 80%;
    margin: auto;
    height: 50vh;
  }
  @media (max-width: 980px) {
    .testscreen_main_div {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        row-gap: 20px;
    }

    .testscreen_main_div > :nth-child(2) {
        grid-row: 1;
    }

    .testscreen_main_div > :nth-child(1) {
        grid-row: 2;
    }
}

  .testscreen_div {
    border-radius: 10px;
    border: 1px solid #efefef;
    background: #fff;
  }

  .test_list_web h4 {
    color: #000;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 90%;
  }

  .test_list_web {
    padding-top: 20px;
    padding-bottom: 20px;
    flex-shrink: 0;
    border-bottom: 0.2px #7e7e7e;
    border-style: dashed;
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
  }

  .test_list_web span {
    float: right;
  }

  .test_list p {
    flex-grow: 1;
  }

  .testscreen_div_count {
    border-radius: 10px;
    border: 1px solid #efefef;
    background: #7c56e9;
    border-radius: 10px;
    border: 1px solid #efefef;
  }

  .testscreen_div_count {
    background: #7c56e9;
    color: white;
    display: grid;
    justify-items: center;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #efefef;
    background: #7c56e9;
    border-radius: 10px;
    border: 1px solid #efefef;
    position: relative;
  }

  .testscreen_div_count h3 {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 28px;
    font-style: italic;
    font-weight: 900;
    line-height: 36px; /* 128.571% */
    text-transform: capitalize;
    padding-top: 32px;
  }

  .top_img_web {
    position: absolute;
    top: 35%;
  }

  .start_count_text_web {
    position: absolute;
    top: 46%;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 70px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 51.429% */
    text-transform: capitalize;
  }

  .web_first {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 30px;
    border: 1px solid #e7e7e7;
    background: #f0f6ff;
    width: 25%;
  }

  .firstImg {
    height: 50%;
  }

  .progress_toggle_mobile {
    /* display: none; */
    margin: auto;
    width: fit-content;
  }

  .loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 93px;
    height: 93px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    position: inherit;
  }

  @media screen and (max-width: 1300px) {
    .firstImg {
      height: 90px;
    }

    .mobile_logodiv {
      padding-top: -1px;
    }

    .left_header {
      font-size: 10px;
    }

    .right_header {
      font-size: 10px;
    }

    .web_main_header img {
      height: 25px;
    }

    .guildLine_mindiv h3 {
      font-size: 18px;
      padding: 0px;
    }

    .guidline_text h3 {
      font-size: 12px;
    }

    .guidline_card {
      height: 45vh;
    }

    .start_count_text_web {
      position: absolute;
      top: 50%;
    }

    .mobile_interviewDetails h2 {
      font-size: 12px;
      text-transform: capitalize;
    }
  }

  @media only screen and (min-device-width: 700px) and (max-device-width: 1050px) {
    .go_to_interview_mobile {
      width: 55%;
      display: block;
    }
  }

  @media screen and (max-width: 1100px) {
    .firstImg {
      height: 90px;
    }

    .mobile_logodiv {
      padding-top: -1px;
    }

    .guidline_text h3 {
      font-size: 10px;
    }

    .start_count_text_web {
      position: absolute;
      top: 50%;
    }

    .left_header {
      font-size: 10px;
    }

    .right_header {
      font-size: 10px;
    }

    .mobile_interviewDetails h2 {
      font-size: 10px;
      text-transform: capitalize;
    }
  }

  // If screen size is more than 1200px wide

  @media only screen and (min-device-width: 350px) and (max-device-width: 820px) {
    .mobile_logo_mindiv {
      // height: 60vh;
      min-height: 60vh;
      display: grid;
      justify-items: center;
    }

    .mobile_details {
      width: 100%;
      // height: 40vh;
      min-height: 40vh;
      flex-shrink: 0;
      border-radius: 30px 30px 0px 0px;
      background: #fff;
      bottom: 0;
      position: absolute;
    }

    .logo_header {
      border-bottom: 1px solid #676666;
    }

    .logo_header img {
      height: 55px;
    }

    .slider_maindiv {
      //height: calc(100% - 120px);
      height: 81%;
      overflow: auto;
    }
  }
}


.ai_progress .ant-progress-inner {
  width: 25rem !important;
  height: 25rem !important;
  @media (max-width: 640px) {
    width: 20rem !important;
    height: 20rem !important;
  }
}


.ai_progress_video .ant-progress-inner {
  width: 15.3rem !important;
  height: 15.3rem !important;
  @media (max-width: 640px) {
    width: 11rem !important;
    height: 11rem !important;
  }
}

.testscreen_main_div .test_count {
  font-family: "Open Sans" !important;
}



.progress_tooltip .ant-popover-arrow {
  &::before {
    background-color: transparent !important;
  }

  // &[data-arrow-background='#E94A47'] .ant-popover-arrow::after {
  //   background-color: #E94A47 !important;
  // }

  // &[data-arrow-background='#49A546'] .ant-popover-arrow::after {
  //   background-color: #49A546 !important;
  // }
  
  // &::after {
  //   background-color: #49A546;
  // }
}
