.customCard{
  &.useDefaultTheme{
    border-radius: 0;
    box-shadow: none;
    .ant-card-body{
      background-color: #F0F6F6;
      padding: 0;
      border-radius: 0;
      box-shadow: none;
    }
  }
}