@import '../Styles/commonStyles.scss'; 

.annotationsWrapper{

  .ant-tabs > .ant-tabs-nav{
    max-height: 90vh
  }
  .questionsTab{
    .ant-tabs-content-holder{
      @include customScrollbar;
      overflow-y: auto;
      height: fit-content;
    }
  }
}