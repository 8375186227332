@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import './Styles/commonComponents.scss';

.App {
  text-align: center;
  font-family: 'Poppins';
  font-size: 14px;
}

#root {
  height: 100%;
  width: 100%;
}

html {
  overflow: hidden;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
}

body {
  height: 100%;
  width: 100%;
  font-size: 1rem; /* Use 'rem' for scalability */
}

.note_input {
  width: 99%;
  outline: none;
  background-color: rgb(207, 235, 247);
  height: auto;
  color: gray;
}

/* Side Navigation */

.MuiDrawer-paper {
  box-sizing: border-box;
  border: none;
  background-color: #fff;
  width: 300px;
  padding: 1rem;
  padding-right: 0;
}

.box_border {
  border: 0.1px solid rgba(0, 0, 0, 0.35);
}

.MuiList-root {
  /* background-color: #0c2556; */
  height: 100%;
  align-items: center;
  /* color: #fff; */
  font-weight: bold;
}

.MuiLink-root {
  color: inherit;
  text-decoration: none;
}

.MuiListItemButton-root {
  margin: 1rem;
  /* box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%); */
  height: fit-content;
  background-color: #0c2556;
  padding: 1em;
}

/* Sidebar open state */
.MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-gutters.css-ddn8n2-MuiButtonBase-root-MuiListItemButton-root {
  margin-top: 3px;
}

.MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-gutters.css-ddn8n2-MuiButtonBase-root-MuiListItemButton-root:hover {
  background-color: #0a7f8f;
  border-radius: 10px;
}

/* Active state */
.active
  .MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-gutters.css-ddn8n2-MuiButtonBase-root-MuiListItemButton-root {
  background-color: #0a7f8f;
  border-radius: 10px;
}

.MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-gutters.MuiListItemButton-root.MuiListItemButton-gutters.css-xb586a-MuiButtonBase-root-MuiListItemButton-root:hover {
  background-color: #0a7f8f;
  border-radius: 10px;
  color: #fff;
}

/* .MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-gutters.MuiListItemButton-root.MuiListItemButton-gutters.css-xb586a-MuiButtonBase-root-MuiListItemButton-root {} */
.css-10hburv-MuiTypography-root {
  font-weight: bold;
}

.logo {
  width: 23%;
  padding-right: 10px;
  /* padding-bottom: 20px; */
}

.Mui-selected {
  background-color: #fff;
  color: black;
}

footer {
  text-align: center;
  /* bottom: 3%;
  position: fixed; */
  color: #fff;
  margin-top: 5px;
  margin-left: 3rem;
}

footer .footer_icon {
  font-size: x-large;
  color: white;
  cursor: pointer;
}

footer .edit_icon {
  padding: 10px;
  border-radius: 10px;
}

footer .edit_icon:hover {
  background-color: rgb(3 105 161);
  border-radius: 10px;
}

footer .info_icon {
  /* padding-left: 46px; */
  padding: 10px;
  border-radius: 10px;
}

footer .info_icon:hover {
  background-color: rgb(3 105 161);
  border-radius: 10px;
}

footer .logout_icon {
  padding: 10px;
  border-radius: 10px;
}

footer .logout_icon:hover {
  background-color: rgb(3 105 161);
  border-radius: 10px;
}

.overflow_scroll {
  overflow-y: scroll;
  overflow-x: hidden;
}

.height_fix {
  height: 30rem;
  margin-top: 4%;
}

/* header */

.header {
  max-height: 4.5rem;
  background-color: #f0f6f6;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  margin: 1rem 0rem;
}

.main_headerdiv {
  flex-grow: 4;
  text-align: start;
  font-weight: bold;
  /* padding: 17px; */
  height: 100%;
}

.text_headerdiv {
  flex-grow: 4;
  text-align: start;
  padding-left: 17px;
  font-weight: bold;
  /* font-family: "Poppins"; */
  font-family: sans-serif;
  /* cursor: pointer; */
}

.textSubHeaderDiv {
  flex-grow: 4;
  text-align: start;
  font-size: 18px;
  /* font-family: "Poppins"; */
  /* cursor: pointer; */
  font-weight: 600;
  font-family: sans-serif;
}

.main_header_searc_div {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 12px 0px;
}

.header_search {
  flex-grow: 16;
}

.header_Notify {
  flex-grow: 2;
}

.header_profile {
  flex-grow: 1;
}

.search {
  width: 95%;
  height: 33px;
  border: 0;
  background-color: #dcdcdcc2;
  outline: 0;
}

input.search {
  width: 95%;
  height: 33px;
  border: 0.5px solid lightgray;
  background-color: #dcdcdcc2;
  outline: 0;
  /* background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 323px center; */
  border-radius: 5px;
}

/* Notification count */
.MuiBadge-badge.MuiBadge-standard.MuiBadge-anchorOriginTopRight.MuiBadge-anchorOriginTopRightRectangular.MuiBadge-overlapRectangular.css-fvc8ir-MuiBadge-badge {
  background-color: red;
  color: white;
  font-weight: bold;
  padding: 10px;
}

.user_name {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding-right: 2px;
}

/* jobs */

h4 {
  padding: 0;
  margin: 0;
  font-weight: 500;
}

h2 {
  padding: 0;
  margin: 0;
}

.addReviewerMainDiv {
  /* height: 466px; */
}

.addReviewerMainDiv h5 {
  margin: 0;
  margin-bottom: 10px;
  text-align: left;
}

.addReviewerMainDiv input {
  padding: 8px;
  width: 100%;
  outline: none;
  border: 0;
  border-bottom: 1px solid;
}

.addCategoryDiv .buttonDiv {
  text-align: center;
  align-items: center;
}

.buttonDiv button {
  padding: 8px;
  background-color: #f1471e;
  color: white;
  border-radius: 8px;
  outline: none;
  border: none;
}

.addCategoryDiv h5 {
  margin: 0;
  margin-bottom: 10px;
}

.addCategoryDiv input {
  padding: 8px;
  width: 100%;
  outline: none;
  border: 0;
  border-bottom: 1px solid;
}

.dialog h4 {
  font-size: 16px;
  font-weight: 600;
}

.login_logo {
  width: 10%;
  object-fit: contain;
  margin: auto;
}

.login {
  width: 100%;
}

.login a {
  color: blue;
  font-size: 12px;
  text-decoration: underline;
  padding-right: 8px;
  cursor: pointer;
}

.login button {
  font-size: 12px;
  font-family: sans-serif;
}

.login input {
  width: -webkit-fill-available;
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  border: 0.5px solid gray;
  width: 98%;
}

.login button {
  width: -webkit-fill-available;
  margin: 5px;
  margin-right: 0px;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  border: 0.5px solid gray;
  background-color: #0a7f8f;
  color: whitesmoke;
  font-size: 16px;
  font-weight: 500;
  width: 98%;
}

.login select {
  width: -webkit-fill-available;
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  border: 0.5px solid gray;
  font-size: 16px;
  font-weight: 500;
}

.icon {
  color: #0c2556;
  cursor: pointer;
  /* font-size: initial; */
}

.searchWrapper {
  border: 0px;
  border-radius: 0px;
  min-height: 0;
  padding: 0;
}

.multi_select {
  border: 0px;
  border-radius: 0px;
  min-height: 0;
  padding: 0;
  background-color: white;
  height: auto;
  border-radius: 10px;
}

.country-list {
  position: fixed;
  z-index: 2;
}

.react-tel-input ul {
  position: fixed;
  z-index: 2;
}

.react-tel-input .country-list {
  position: fixed;
  z-index: 2;
}

.required {
  padding-left: 5px;
  font-size: 12px;
  font-family: sans-serif;
  color: red;
  margin: 0;
  width: 100%;
}

.error_h1 {
  font-size: 25px;
  color: red;
  font-family: sans-serif;
  text-align: center;
}

.wrapper-class {
  /* border-radius: 10px; */
  /* border: 0.1px solid rgba(0, 0, 0,  0.35) */
}

.editor-class {
  background-color: white;
  border-radius: 10px;
  /* height: 200px; */
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  /* overflow: auto; */
  border: 0.1px solid rgba(0, 0, 0, 0.35);
}

.addField {
  width: fit-content;
  background-color: #0a7f8f;
  color: white;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
  display: flex;
  float: right;
  line-height: 1;
}

.multi_select span {
  /* display: none;
  padding: 0; */
}

ul {
  border: 0px;
}

.dangerousHtml ul {
  list-style: initial;
  margin: initial;
  padding: revert;
}

.enter {
  width: fit-content;
  padding: 5px;
  background-color: #f1471e;
  border-radius: 10px;
  color: white;
  float: right;
  margin-bottom: 10px;
}

strong {
  display: contents;
}

#search_type {
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid gray;
  outline: none;
  background-color: white;
}

.form_content {
  margin: 5px;
}

.icon_div {
  height: 10%;
  width: 100%;
  position: absolute;
  bottom: 3%;
}

.icon_row {
  font-size: large;
  cursor: pointer;
}

.form_content span {
  padding-right: 8px;
}

.form_content span.ant-checkbox {
  padding: 0px !important;
}

.form_content h5 {
  font-size: 15px;
  color: #0c2556;
  /* font-weight: 800; */
  display: flex;
}

.form_content p {
  /* padding-left: 22px; */
  font-size: 15px;
}

.form_content_input {
  width: 99%;
  /* border: 1px solid; */
  border-radius: 10px;
  padding: 8px;
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.manual_buttons {
  text-align: center;
}

.manual_buttons button {
  width: fit-content;
  background-color: #f1471e;
  color: white;
  text-align: center;
  margin: auto;
  margin: 5px;
  border-radius: 10px;
  padding: 8px;
}

.edit_candidate_maindiv {
  width: 98%;
  margin: auto;
  margin-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  overflow: auto;
  height: 90%;
  padding: 10px;
}

.edit_candidate_maindiv ::-webkit-scrollbar {
  display: none;
}

.editCandidate_form {
  display: grid;
  grid-template-columns: 47% 47%;
  grid-column-gap: 50px;
}

.skills_drop {
  width: 98%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  background-color: white;
  padding: 8px;
}

.popup_button {
  padding: 6px;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 25px;
  cursor: pointer;
  width: auto;
  background: #0a7f8f;
  border-radius: 4px;
  color: white;
  border: none;
  font-size: 14px;
  font-family: sans-serif;
  font-weight: 600;
  height: 42px;
}

.popup_skills {
  width: 118px;
  height: 42px;
  border-radius: 4px;
  background: #0a7f8f;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.cancel_btn {
  width: 118px;
  height: 42px;
  border-radius: 4px;
  border: 1px solid #0a7f8f;
  color: #0a7f8f;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.add_details {
  height: 100%;
  text-align: center;
}

.add_details h4 {
  font-size: 14px;
  text-align: left;
  padding-left: 3px;
  font-family: sans-serif;
}

.add_details input {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 100%;
  border-radius: 10px;
  padding: 8px;
  /* margin-top: 10px; */
  border: none;
  outline: none;
  padding-left: 10px;
  margin: 10px;
  margin-left: 0px;
}

.wrapper {
  max-width: 75%;
  margin: auto;
}

.prescreen_div {
  width: 100%;
  padding: 10px;
}

.prescreen_div input {
  width: 75%;
  outline: none;
  border-bottom: 1px solid;
}

.prescreen_div button {
  background-color: #f1471e;
  color: white;
  border-radius: 10px;
  padding: 5px;
  float: right;
}

.param_list_view {
  padding: 5px;
  height: 200px;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  margin-top: 5px;
}

.param {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 5px;
  border-radius: 10px;
  margin-top: 8px;
}

.param_tick_icon {
  color: #0c2556;
  cursor: pointer;
  font-size: large;
  float: right;
  padding-right: 10px;
}

.addLevel_div {
  padding: 20px;
  /* height: 380px; */
  width: 100%;
  text-align: center;
}

.addLevel_div h5 {
  font-size: 14px;
  /* font-weight: 600; */
  text-align: left;
  padding-bottom: 10px;
  padding-top: 10px;
  font-family: sans-serif;
}

.addLevel_div select {
  width: 100%;
  padding: 7px;
  border-radius: 5px;
  background-color: white;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  border: 1px solid #d9d9d9;
  outline: #0a7f8f;
}

.addLevel_div select :hover {
  border: 1px solid #0a7f8f;
}

.select_interviewer_list:hover {
  border: 1px solid #0a7f8f;
}

.searchWrapper:hover {
  border: 1px solid #0a7f8f;
}

.addLevel_div input[type='radio'] {
  accent-color: #0a7f8f;
}

.assessment_grid {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  column-gap: 12px;
}

.assessment_card {
  flex-shrink: 0;
  border-radius: 4px;
  background: #fff;
  padding: 10px;
}

.infraction_div {
  flex-shrink: 0;
  border-radius: 4px;
}

.infraction_div img {
  width: 100%;
  height: 90%;
  object-fit: scale-down;
}

.wrapper {
  margin: 0;
  min-width: -webkit-fill-available;
}

.ql-snow {
  text-align: left;
  background-color: #fff;
  /* text-transform: capitalize; */
}

.ql-toolbar {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.ql-container {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.level_insidecard {
  width: 155px;
  height: 130px;
  color: white;
  border-radius: 10px;
  background-color: #fff;
  color: black;
}

.display_text {
  font-size: 12px;
  /* padding: 2px; */
  background-color: #fff;
  margin-left: 2px;
  border-radius: 3px;
  color: black;
  padding-left: 2px;
  font-family: sans-serif;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiDataGrid-paper.css-154sxbz-MuiPaper-root-MuiDataGrid-paper {
  max-height: 260px;
}

.ant-slider-horizontal .ant-slider-mark {
  top: -15px;
  font-size: 10px;
}

.validate_message {
  text-align: left;
  color: #ff4d4f;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  padding-top: 2px;

  /* padding-left: 5px;
  font-size: 12px;
  font-family: sans-serif;
  color: red;
  margin: 0;
  width: 100%; */
}

.addItemSpan {
  width: 96%;
  display: flex;
  padding: 2px;
  column-gap: 10px;
}

.addItemSpan input {
  flex-grow: 2;
  /* outline: 0; */
  border: none;
  border-bottom: 1px solid black;
}

.sample_question .ant-form-item {
  margin-bottom: 2px !important;
}

.rfhSelect .ant-form-item {
  margin-bottom: 0px !important;
}

.ant-form-item {
  margin-bottom: 10px;
}


/* param css */

.popupBg {
  background: linear-gradient(
    270deg,
    #efffff 0.16%,
    rgba(239, 255, 255, 0) 99.89%
  );
}

.popupBorder {
  border-bottom: 1px solid #e2e2e2e2;
  padding: 10px;
  padding-left: 4px;
  display: grid;
  grid-template-columns: 25px 150px auto;
  grid-column-gap: 5px;
  height: 45px;
  align-items: center;
}

.skillsText {
  border-radius: 6px;
  border: 1px solid #cecece;
  padding: 4px 8px 4px 8px;
  width: fit-content;
}

@media screen and (min-width: 0px) and (max-width: 650px) {
  footer {
    position: relative;
    bottom: -3%;
  }

  h2 {
    font-size: 14px;
  }

  .header_profile {
    padding-right: 5px;
  }

  .header {
    padding-top: 10px;
  }

  .addJobCard {
    height: 25%;
  }

  .addJobCard div {
    padding-top: 10px;
  }
}

button.ant-switch {
  background: #bfbfbf !important;
}
:where(.ant-switch).ant-switch.ant-switch-checked:hover:not(
    .ant-switch-disabled
  ) {
  background: #4096ff !important;
}
:where(.ant-switch).ant-switch.ant-switch-checked {
  background: #0a7f8f !important;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.document-container p img {
  width: 100px !important;
  display: block !important;
}

.document-container img {
  display: none !important;
}

.round_checkbox span.ant-checkbox-inner {
  border-radius: 50% !important;
}

/* .round_checkbox span.ant-checkbox-inner:focus-visible {
  border-radius: 50% !important;
} */

.round_checkbox .ant-checkbox::after {
  border-radius: 50% !important;
}

.available_height {
  height: -webkit-fill-available;
}

.link {
  color: blue !important;
  text-decoration: underline !important;
}

.full_width {
  width: 100% !important;
}

/* .custom-countrycode {
  height: 30px;
}

.custom-countrycode input {
  border: none !important;
} */
/* .phone-coutrycode .ant-form-item {
  margin: 0px !important;
  width: 100%;
} */

input[type='number'] {
  -moz-appearance: textfield;
}