.aiReviewContent {
  .aiInterview_header {
    width: 100%;
    height: auto;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    // background: #f8faff;
    background: #fff;
    padding: 18px;
  }

  .aiInterview_header_div h3 {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .aiInterview_header_div {
    display: grid;
    grid-template-columns: 75% 24%;
    column-gap: 10px;
  }

  .ai_left_header {
    color: #3a3939;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px;
  }

  .ai_right_header {
    color: #3a3939;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 11px;
  }

  .ai_level {
    color: #0a7f8f;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .ai_interview_details {
    color: #535353;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    float: right;
  }

  .aiInterview_header button {
    flex-shrink: 0;
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    flex-shrink: 0;
    border-radius: 4px;
    background: #0a7f8f;
    width: 121px;
    height: 37px;
  }

  .assessment_maindiv {
    width: 100%;
    height: fit-content;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid #ededed;
    background: #f8faff;
    padding: 18px;
  }

  .assessment_grid {
    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    column-gap: 12px;
  }

  .tabSwitchDiv {
    flex-shrink: 0;
    border-radius: 4px;
    background: #fff;
    padding: 10px;
    flex-grow: 1;
  }

  .assessment_card {
    flex-shrink: 0;
    border-radius: 4px;
    background: #fff;
    padding: 10px;
  }

  .assessment_card h4 {
    color: #0c2556;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 138.462% */
    text-transform: capitalize;
  }

  .assessment_grid_auto {
    display: flex;
    column-gap: 12px;
  }

  .assessment_card_auto {
    flex-shrink: 0;
    border-radius: 4px;
    background: #fff;
    padding: 10px;
    flex-grow: 1;
  }

  .assessment_card_auto h4 {
    color: #0c2556;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 138.462% */
    text-transform: capitalize;
  }

  .chart_div {
    text-align: center;
    display: grid;
    justify-items: center;
  }

  .infraction_count {
    color: #dd841d;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 50px;
  }

  .infraction_header {
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 50px;
  }

  .infraction_div {
    flex-shrink: 0;
    border-radius: 4px;
  }

  .infraction_div img {
    width: 100%;
    height: 90%;
  }

  .viewAll_infraction {
    color: #0a7f8f;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    text-decoration-line: underline;
    text-transform: capitalize;
    float: right;
    padding-right: 30px;
  }

  .resume_div {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    column-gap: 15px;
    padding: 10px;
    padding-bottom: 45px;
  }

  .test_maindiv {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    column-gap: 15px;
    row-gap: 15px;
  }

  .resume_div_img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }

  .match_div {
    width: 100%;
    height: 400px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #00c5b9;
  }

  .not_Match {
    width: 100%;
    height: 400px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #f15e79;
  }

  .match_img {
    // width: 80%;
    // height: 50%;
    margin: auto;
    text-align: center;
    padding-top: 90px;
  }
  .not_Match h3 {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 112.5% */
    text-transform: capitalize;
    text-align: center;
    padding-top: 10px;
  }

  .match_div h3 {
    color: #fff;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-transform: capitalize;
    text-align: center;
    padding-top: 45px;
  }

  .container {
    position: relative;
    text-align: center;
    color: white;
  }

  .bottom-right {
    position: absolute;
    bottom: 35px;
    right: 70px;
    font-size: 16px;
    font-weight: 600;
  }

  .text_maindiv {
    padding: 20px;
  }
  .text_maindiv ul {
    padding: 10px;
  }

  .text_maindiv li {
    color: #fff;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    text-transform: capitalize;
  }

  .resume_div h4 {
    color: #0c2556;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 138.462% */
    text-transform: capitalize;
    padding: 12px;
    padding-left: 0;
  }

  .device_info {
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid #ebebeb;
    background: #fff;
    padding: 8px;
    display: grid;
    grid-template-columns: 90% 10%;
  }

  .testHeader {
    color: #a3aed0;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.28px;
  }

  .test_data {
    color: #2b3674;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.32px;
  }

  .scoreData {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 8px;
    padding: 2px;
    row-gap: 8px;
    text-align: center;
    width: 40%;
    margin: auto;
  }

  .ant-avatar img {
    object-fit: scale-down;
  }

  .scoreData li {
    color: #535353;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
  }

  .infraction_data {
    color: #0c2556;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 81.818% */
    text-transform: capitalize;
    text-align: center;
  }

  .dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
  }

  .clarity {
    background-color: #086b79;
  }

  .fluency {
    background-color: #00bf63;
  }

  .grammar {
    background-color: #d1d605;
  }

  .vocabulary {
    background-color: #ff7171;
  }

  .coherence {
    background-color: #cb1cff;
  }

  .notAnswered {
    background-color: red;
  }

  .interview_feedback_info {
    // width: 65%;
    margin: auto;
    background-color: #f8faff;
    border: 0.5px solid #dbdbdb;
    padding: 10px;
  }

  .header_name {
    color: #000;
    font-size: 18px;
    font-family: Poppins;
    font-weight: 600;
    line-height: 50px;
  }

  .feedBackContent {
    width: 100%;
    height: auto;
    flex-shrink: 0;
    border-radius: 4px;
    // border: 0.5px solid #dbdbdb;
    background: rgba(250, 250, 250, 0.33);
    // padding: 20px;
  }

  .feedbackContent_header {
    color: #0a7f8f;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 600;
  }

  .avatar_div {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #ebf8fa;
    text-align: center;
    line-height: 2.6;
  }

  .ant-avatar img {
    object-fit: contain;
  }

  .bottom_banner {
    width: 100%;
    height: 65px;
    // transform: rotate(-90deg);
    flex-shrink: 0;
    border-radius: 4px;
    background: #ebf8fa;
    bottom: 0;
    position: relative;
  }

  .progImg_div {
    height: 165px;
    position: relative;
    z-index: 1;
  }

  .left_header {
    color: #3a3939;
    font-size: 16px;
    font-family: 'Poppins';
  }

  .right_data {
    color: #3a3939;
    font-size: 16px;
    font-family: 'Poppins';
    font-weight: 600;
  }

  .question_p {
    color: #000;
    font-size: 16px;
    font-family: Poppins;
    text-transform: capitalize;
    //padding-bottom: 10px;
    font-weight: 600;
  }

  .answer_box {
    width: 90%;
    border-radius: 4px;
    border: 1px solid rgba(169, 169, 169, 0.42);
    padding: 4px;
    margin-top: 10px;
  }
  .vertical_maindiv {
    display: flex;
  }

  .vertical {
    // display: flex;
    padding-right: 25px;
    margin-top: 10px;
    font-family: 'Poppins';
    font-weight: 14px;
    text-align: center;
    text-transform: capitalize;
  }

  .nonvertical_maindiv {
    margin-top: 10px;
  }

  .nonvertical {
    display: flex;
    margin-top: 10px;
    font-family: 'Poppins';
    font-weight: 14px;
    text-transform: capitalize;
  }

  .save {
    width: 118px;
    height: 42px;
    background: #0a7f8f;
    border-radius: 4px;
    color: white;
  }

  .cancel {
    width: 118px;
    height: 42px;
    height: 42px;
  }

  input[type='radio'] {
    accent-color: #0a7f8f;
  }

  .passBox {
    background-color: #e8fff3;
    width: 369px;
    height: 59px;
    flex-shrink: 0;
    margin: auto;
    padding: 20px;
    border-radius: 6px;
    margin-top: 20px;
  }

  .pass_span {
    color: #006932;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    padding-left: 14px;
  }

  .passBox img {
    padding-left: 120px;
  }

  .onHoldBox {
    width: 369px;
    height: 59px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #fff8dd;
    margin: auto;
    padding: 20px;
    margin-top: 20px;
  }

  .onHoldBox img {
    padding-left: 218px;
  }

  .onHold_span {
    color: #876b00;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    padding-left: 14px;
  }

  .rejectBox {
    width: 369px;
    height: 59px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #ffecec;
    margin: auto;
    padding: 20px;
    margin-top: 20px;
  }

  .rejectBox img {
    padding-left: 232px;
  }

  .rejectBox_span {
    color: #f45c5c;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    padding-left: 14px;
  }

  .aiInterview_header {
    width: 100%;
    height: auto;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    // background: #f8faff;
    background: #fff;
    padding: 18px;
    margin-top: 10px;
  }

  .aiInterview_header_div h3 {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .aiInterview_header_div {
    display: grid;
    grid-template-columns: 75% 24%;
    column-gap: 10px;
  }

  .ai_left_header {
    color: #3a3939;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px;
  }

  .ai_right_header {
    color: #3a3939;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 11px;
  }

  .ai_level {
    color: #0a7f8f;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .ai_interview_details {
    color: #535353;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    float: right;
  }

  .aiInterview_header button {
    flex-shrink: 0;
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    flex-shrink: 0;
    border-radius: 4px;
    background: #0a7f8f;
    width: 121px;
    height: 37px;
  }

  .aiInterview_header button.aiInterview_header_button {
    background-color: rgba(3, 165, 58, 0.10);
    border-radius: 55px;
    color: #0A8F4F;
    font-size: 14px;
    font-weight: 600;
  }

  .aiInterview_header button.interview_failed {
    color: #8f340a !important;
    background-color: rgba(165, 62, 3, 0.1) !important;
  }

  .closeButton {
    flex-shrink: 0;
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    flex-shrink: 0;
    border-radius: 4px;
    background: #0a7f8f;
    width: 121px;
    height: 37px;
  }

  .camera_icons {
    font-size: xx-large;
    color: #00b165;
  }
}