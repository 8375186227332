@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
.interviewMainDiv {
  width: 100%;
  height: 100vh;
  // web
  overflow: auto;
  font-family: 'Open Sans';
  background-color: #f7f7f7;

  .interviewDetailsMainDiv {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .interviewDetailsSubDivOne {
    //height: 55vh;
    flex-grow: 1;
    background-image: url('../Assets/images/interview/deatilsOne.png');
    background-repeat: no-repeat;
    background-position: 100%;
    background-color: #f7f7f7;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .interviewDetailsSubDivTwo {
    // height: 45vh;
    flex-grow: 1;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .goToInterview {
    display: flex;
    padding: 12px 40px;
    justify-content: center;
    align-items: center;
    gap: 17px;
    border-radius: 86px;
    background: #11b2ab;
    color: #fff;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .helloText {
    color: #000;
    font-family: 'Open Sans';
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .welcomeText {
    color: #000;
    font-family: 'Open Sans';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .interviewDetailsSubDivOne .online_test_logo {
    margin: auto;
    //margin-bottom: 40px;
    object-fit: scale-down;
  }

  .centerDiv {
    position: absolute;
    top: 45%;
    margin: 10px;
    padding-left: 10px;
    font-size: large;
    border-radius: 16px;
    border: 1px solid #e8e8e8;
    background: #fff;
    box-shadow: 0px 3px 4px 2px rgba(236, 236, 236, 0.34);
    height: 12rem;
  }

  .centerDiv h1 {
    color: #000;
    font-family: 'Open Sans';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  .alice-carousel {
    position: relative;
    width: 100%;
    margin: auto;
    direction: ltr;
    height: 100%;
  }

  // .alice-carousel div{
  //    height: 70%;
  // }

  .alice-carousel .guidline_item {
    display: flex;
    flex-wrap: nowrap;
    width: 65%;
    margin: auto;
    background: #fff;
    height: 100%;
    border-radius: 6px;
    padding: 30px;
    flex-direction: row;
  }

  .alice-carousel__wrapper {
    height: 100%;
  }

  .guidlines {
    padding: 20px;
    height: 100%;
  }

  .guidlinesHeaderDiv {
    //height: 15%;
    height: fit-content;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .guidlinesText {
    color: #000;
    font-family: 'Open Sans';
    // font-size: 26px;
    font-style: normal;
    font-weight: 400;
    //line-height: 46px;
    // text-align: center;
    width: 90%;
    margin: auto;
    padding: 20px;
  }

  .guidlinesContent_div {
    display: flex;
    height: fit-content;
  }

  // .alice-carousel__dots{
  //     margin: 0;
  //     margin-top: 4px;
  // }

  .guidlinesHeaderDiv h1 {
    color: #000;
    font-family: 'Open Sans';
    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    //line-height: 6;
  }

  .guidlinesFooterDiv {
    //height: 10%;
    height: fit-content;
    // padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contains_maindiv {
    height: auto;
    background-color: #fff;
    border-radius: 6px;
    width: 60%;
    margin: auto;
    padding: 30px;
  }

  .QuestionCount_maindiv {
    display: flex;
    padding-bottom: 15px;
    padding-top: 15px;
  }

  .QuestionCountHeader {
    color: #000;
    font-family: 'Open Sans';
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .QuestionCountSubHeader {
    color: #474747;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
  }

  .questionCountNumber {
    padding: 10px 15px 10px 15px;
    border-radius: 6px;
    background: #fff4e4;
    color: #998b67;
    font-family: 'Open Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .questionViewMainDiv {
    display: flex;
    flex-direction: column;
    width: 85%;
    height: 100%;
    margin: auto;
  }
  .questionViewHeader {
    height: 15%;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .totalDuration_div {
    border-radius: 30px;
    background: #fee8d3;
    color: #ff8a33;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    height: fit-content;
    padding: 4px 8px 4px 8px;
    width: fit-content;
  }

  .questionViewFooter {
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 15px;
  }

  .submit_btn {
    display: flex;
    padding: 12px 40px;
    border-radius: 86px;
    background: #11b2ab;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #fff;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .skip_btn {
    display: inline-flex;
    padding: 12px 40px;
    justify-content: center;
    align-items: center;
    gap: 17px;
    border-radius: 86px;
    border: 1px solid #000;
    color: #000;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .questions_maindiv {
    display: flex;
    gap: 30px;
    flex-direction: row;
  }

  .question_div {
    flex-grow: 2;
    flex-shrink: 1;
    border-radius: 6px;
    background: #fff;
    padding: 25px 50px 50px 50px;
    border: 1px solid #afd6d4;
    height: fit-content;
  }

  .question_div h1 {
    color: #000;
    font-family: 'Open Sans';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }
  .pText {
    padding-left: 10px;
  }

  .option_span {
    color: #000;
    font-family: 'Open Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 46px;
  }

  .question_details {
    flex-grow: 1;
    flex-shrink: 0;
    border-radius: 6px;
    background: #fff;
    padding: 25px;
    height: fit-content;
  }
  .question_details_maindiv {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
  }

  .avathar {
    background-color: #f7f7f7;
    padding: 15px;
    border-radius: 50px;
    color: #11b2ab;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .applyBorderBottom {
    border-bottom: 1px solid #e8e8e8;
  }

  .valueText {
    color: #000;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }
  .questionNumberDiv {
    display: flex;
    gap: 20px;
    align-items: center;
    padding-bottom: 20px;
  }

  .questionNumber {
    color: #000;
    font-family: 'Open Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .questionJobTitle {
    color: #000;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 4px;
  }

  .titleText {
    color: #11b2ab;
  }

  .questionSectionTotalTime {
    flex-shrink: 0;
    border-radius: 11px;
    background: rgba(255, 228, 133, 0.72);
    color: #816c24;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    padding: 4px 8px 4px 8px;
    margin-left: 4px;
    width: fit-content;
  }

  .detailsScreen {
    width: fit-content;
    margin: 0 auto auto auto;
    height: 65vh;
    padding-top: 50px;
  }

  .detailsScreen_skipview {
    width: fit-content;
    margin: 0 auto auto auto;
    height: 65vh;
    padding-top: 50px;
  }

  .hitText {
    color: #545454;
    font-family: 'Open Sans';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 46px;
  }

  .question_div textarea {
    width: 100%;
    border-radius: 6px;
    border: 1px solid rgb(211, 207, 207);
    padding: 10px;
    height: 180px;
  }

  .questionTime {
    border-radius: 11px;
    background: #f7f7f7;
    color: #b8b8b8;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    gap: 4px;
    width: fit-content;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 2px;
    padding-top: 2px;
    float: right;
  }

  .time_div {
    width: 100%;
    padding-bottom: 30px;
  }

  .finalScreen_maindiv {
    width: 100%;
    height: 100%;
    background-image: url('../Assets/images/interview/finalImg1.png');
    background-repeat: no-repeat;
    background-position: bottom left;
  }

  .finalScreen_div {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 40%;
    margin: auto;
  }

  .finalScreen_leftdiv {
    padding: 20px;
    text-align: center;
    width: fit-content;
  }

  .finalScreen_leftdiv h1 {
    color: #000;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 33px;
    font-style: normal;
    font-weight: 600;
    line-height: 43px; /* 130.303% */
    text-transform: uppercase;
    padding-top: 10px;
    width: 95%;
    margin: auto;
  }

  .finalScreen_leftdiv h2 {
    color: #2c2c2c;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 31px;
    padding-top: 10px;
    width: 90%;
    margin: auto;
  }

  .finalScreen_leftdiv img {
    margin: auto;
  }

  .finalScreen_rightdiv {
    flex-shrink: 0;
    border-radius: 6px;
    background: #fff;
    padding: 30px;
    width: fit-content;
  }

  .finalScreen_rightdiv h1 {
    color: #000;
    font-family: 'Open Sans';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  .finalScreen_rightdiv ul {
    margin-top: 10px;
  }

  .finalScreen_rightdiv ul li {
    color: #262626;
    font-family: 'Open Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
  }

  .tabActivePopup_h1 {
    color: #000;
    font-family: 'Open Sans';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }

  .tabActivePopup_p {
    color: #262626;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .tabActivePopup_button {
    display: flex;
    padding: 12px 40px;
    justify-content: center;
    align-items: center;
    gap: 17px;
    border-radius: 86px;
    background: #11b2ab;
  }

  .questionView_btn_div {
    display: flex;
    width: 100%;
    gap: 10px;
  }

  .skipQuestion_view_btn_div {
    display: flex;
    gap: 10px;
  }

  .pText:hover {
    background: #f7f7f7;
  }

  #optionQuestion:checked ~ .pText {
    background-color: orange;
    color: black;
  }

  input[type='radio']:checked ~ .pText {
    background-color: orange;
    color: black;
  }

  .option_div {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-device-width: 770px) {
    .firstPage_btn_div {
      position: absolute;
      bottom: 0px;
      width: 100%;
      padding: 20px;
    }
  }

  @media screen and (max-width: 770px) {
    .interviewDetailsSubDivOne {
      background-size: cover;
    }

    .guidline_item {
      width: 100%;
    }

    .contains_maindiv {
      width: 100%;
    }
    .questionJobTitle {
      display: flex;
      flex-direction: column;
      gap: 3px;
    }
    .questionSectionTotalTime {
      margin-left: 0;
    }

    .questions_maindiv {
      flex-direction: column;
    }

    .question_div textarea {
      max-height: 20vh;
    }

    .question_div h1 {
      color: #000;
      font-size: 17px;
    }

    .option_span {
      font-size: 15px;
    }

    .helloText {
      font-size: 56px;
    }

    .welcomeText {
      font-size: 20px;
    }

    .guidlinesFooterDiv {
      width: 90%;
      position: absolute;
      bottom: 5px;
      padding: 0;
    }

    .guidline_btn_div {
      width: 100%;
      margin: auto;
      text-align: center;
    }

    .alice-carousel__dots {
      margin: 0;
    }

    .interviewDetailsSubDivOne {
      background-position: 78%;
    }

    .alice-carousel .guidline_item {
      flex-direction: column-reverse;
      width: 100%;
      padding: 0;
      background: none;
    }

    .alice-carousel .guidline_item img {
      object-fit: scale-down;
      width: 85%;
      margin: auto;
      padding: 15px;
    }

    // .guidlinesText{
    //     font-size: 20px;
    // }

    .goToInterview {
      width: -webkit-fill-available;
    }

    .guidlinesHeaderDiv {
      padding: 0;
    }

    .guidlinesHeaderDiv h1 {
      font-size: 28px;
      padding-bottom: 25px;
    }

    .detailsScreen {
      width: 100%;
    }

    .detailsScreen_skipview {
      width: 100%;
      padding: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .QuestionCountHeader {
      font-size: 14px;
    }

    .QuestionCountSubHeader {
      font-size: 12px;
    }

    .questionCountNumber {
      font-size: 16px;
    }

    .questionViewHeader {
      height: fit-content;
      padding-right: 0px;
    }

    .totalDuration_div {
      font-size: 12px;
      font-weight: 600;
    }

    .questionViewMainDiv {
      width: 100%;
      padding: 10px;
    }

    .questionNumberDiv {
      padding-bottom: 10px;
    }

    .questionNumber {
      font-size: 18px;
    }

    .questionJobTitle {
      font-size: 12px;
    }

    #td {
      display: none;
    }

    #qa {
      display: none;
    }

    .question_details {
      padding: 0;
    }

    .avathar {
      font-size: 12px;
    }

    .valueText {
      font-size: 14px;
    }

    .question_details_maindiv p {
      font-size: 12px;
    }

    .questionViewFooter {
      padding: 0;
      justify-content: center;
      bottom: 8px;
      position: absolute;
      width: 94%;
      height: auto;
    }
    .skip_btn {
      flex-grow: 1;
      padding: 10px;
    }

    .submit_btn {
      flex-grow: 1;
      padding: 10px;
    }

    .questionView_btn_div {
      width: 100%;
    }

    .question_div {
      padding: 5px 15px 5px 15px;
      //    max-height: 50vh;
      //    overflow: auto;
    }

    .option_div {
      max-height: 30vh;
      overflow: auto;
    }

    .option_span {
      line-height: 30px;
    }

    .time_div {
      padding-bottom: 25px;
    }

    .hitText {
      font-size: 12px;
      line-height: 25px;
    }

    .skipQuestion_view_btn_div {
      flex-direction: column;
      width: 100%;
    }

    .finalScreen_div {
      flex-direction: column;
      width: 100%;
      padding: 15px;
      //gap: 10px;
    }

    .finalScreen_leftdiv {
      padding: 0px;
    }

    .finalScreen_leftdiv h1 {
      font-size: 18px;
      line-height: normal;
    }

    .finalScreen_leftdiv h2 {
      font-size: 16px;
      line-height: normal;
    }

    .finalScreen_rightdiv {
      width: 100%;
      padding: 20px;
    }

    .finalScreen_rightdiv h1 {
      font-size: 18px;
    }

    .finalScreen_rightdiv ul li {
      font-size: 14px;
    }

    // .exit_btn{
    //     display: none;
    // }
  }
}
