@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
  font-family: 'Poppins', sans-serif;
}

.modal-container {
  /* background-color: aliceblue; */
  font-family: 'Poppins';
}

.modal-interview {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 15px;
  margin: 5px;
  font-size: 14px;
  font-weight: 100;
}

.modal-interview p {
  font-size: 12px;
}

.modal-from-div {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: 20px;
  padding: 15px;
}

.modal-from-div h5 {
  margin: 5px !important;
}

.modal-from-div input {
  width: 75%;
  height: 35px;
  /* margin: 12px; */
  border-radius: 30px;
  outline: none;
  border: 0;
  background-color: #fff;
  /* box-shadow: 0px -3px 3px rgba(14, 13, 13, 0.08); */
  font-size: 14px;
  font-weight: 400;
  text-align: start;
  padding-left: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.modal-btn-submit {
  text-align: end;
}

.modal-btn-submit button {
  height: 30px;
  width: 85px;
  border-radius: 10px;
  margin-top: 10px;
  background-color: #f1471e;
  border: 0;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.modal-interview-deatils-div {
  display: flex;
}

.modal-interview-deatils-div-p {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 600;
  width: 70%;
}

.modal-interview-deatils-div p {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 600;
}

.modal-logo {
  width: 23%;
  padding-right: 10px;
  padding-bottom: 20px;
}

.modal-logo-span {
  font-family: 'poppins';
  font-size: 16px;
  font-weight: 600;
}

.logo-div {
  margin-top: 10px;
  margin-bottom: 20px;
}

.modal-pdf-link {
  /* background-color: blue; */
  color: black;
  float: right;
  font-size: 20px;
  /* margin: 10px; */
}

.font-size-10 {
  font-size: 10px;
}

.primary-box-shadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.pagination ul {
  list-style-type: none;
  display: flex;
}

.pagination a {
  color: black;
  font-weight: 600;
}

.tabs.Mui-selected {
  background-color: #f0f6f6;
  color: #0a7f8f;
  /* padding-left: 6px;
  padding-right: 6px; */

  padding: 8px;
  height: auto;
  flex-direction: column;
}

.tabs:hover {
  /* background-color: #d9d9d9; */
  cursor: pointer;
  color: black;
}

.tabs {
  height: 36px;
  width: auto;
  /* border-radius: 20px; */
  /* //background-color: white; */
  border: 0;
  color: black;
  font-size: 14px;
  margin-right: 10px;
  padding: 8px;
  margin-top: 2px;
}

.myschedule_maindiv {
  width: 100%;
  margin-top: 10px;
  height: 100%;
  overflow: auto;
}

.date_picker {
  width: 22rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  height: 3rem;
  /* z-index: 1 !important; */
}

.MuiDialog-root {
  z-index: 1 !important;
}

/* .fc .fc-scrollgrid {
  background-color: darkslategrey;
} */

.list_icon {
  flex-grow: 1;
  font-size: large;
  cursor: pointer;
  color: #0c2556;
  /* padding: 5px; */
  border-radius: 5px;
  padding: 1px;
  margin-left: 2px;
  margin-right: 5px;
}
.list_icon_plus {
  flex-grow: 1;
  font-size: small;
  cursor: pointer;
  color: #fff;
  /* padding: 5px; */
  border-radius: 5px;
  padding: 1px;
  margin-left: 2px;
  margin-right: 5px;
}
.data_Grid {
  width: 100%;
  /* // height: 40vh; */
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  border-radius: 5px;
  /* margin-top: 35px; */
  height: 60vh;
}

:where(.css-dev-only-do-not-override-1i9hnpv).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  width: 100%;
  /* height: 45px; */
  padding: 0 11px;
}

.primary-btn {
  /* height: 30px;
  width: fit-content;
  border-radius: 10px;
  margin-top: 10px;
  background-color: #f1471e;
  border: 0;
  color: #fff;
  font-size: 12px; */
  /* font-weight: 600; */
  /* cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */

  padding: 6px;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 10px;
  width: auto;
  border-radius: 8px;
  background-color: #f1471e;
  color: white;
  border: none;
  font-size: 14px;
  font-family: sans-serif;
  font-size: 12px;
  cursor: pointer;
}

.secondary-btn {
  /* height: 30px;
  width: fit-content;
  border-radius: 10px;
  margin-top: 10px;
  background-color: #eeeeee;
  border: 0;
  color: black;
  font-size: 12px; */
  /* font-weight: 600; */
  /* cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */

  padding: 6px;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 10px;
  width: auto;
  border-radius: 8px;
  background-color: #e7e7e7;
  color: #000;
  border: none;
  font-size: 14px;
  font-family: sans-serif;
  font-size: 12px;
  cursor: pointer;
}

.secondary-popup-button {
  /* width: fit-content;
  padding: 10px;
  background-color: #eeeeee;
  color: black;
  border-radius: 10px;
  margin-top: 4px; */

  padding: 6px;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 10px;
  width: auto;
  border-radius: 8px;
  background-color: #e7e7e7;
  color: #000;
  border: none;
  font-size: 14px;
  font-family: sans-serif;
  font-size: 12px;
  cursor: pointer;
}

.css-faipsv-MuiPaper-root-MuiAccordion-root:first-of-type {
  border-radius: 30px;
}

/* Settings --> Accordian */
.css-1qtngpl-MuiPaper-root-MuiAccordion-root:first-of-type {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.css-1qtngpl-MuiPaper-root-MuiAccordion-root:last-of-type {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.css-1qtngpl-MuiPaper-root-MuiAccordion-root:before {
  left: auto !important;
}

.css-9fqbdo-MuiPaper-root-MuiAccordion-root:last-of-type {
  border-bottom-left-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

.css-9fqbdo-MuiPaper-root-MuiAccordion-root:first-of-type {
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
}

:where(.css-dev-only-do-not-override-1i9hnpv).ant-switch {
  background: rgba(0, 0, 0, 0.45);
}

.main-height {
  height: calc(100% - 90px);
  /* display: flex; */
  overflow: auto;
}

.main-height::-webkit-scrollbar {
  display: none;
}

.css-9fqbdo-MuiPaper-root-MuiAccordion-root {
  width: 100% !important;
  margin-bottom: 30px !important;
  position: inherit !important;
}

.css-9fqbdo-MuiPaper-root-MuiAccordion-root:before {
  position: none !important;
}

.ant-space-item {
  width: 100% !important;
}

.iiKhtO {
  position: none !important;
}

.plusCircle {
  position: inherit;
}

.iiKhtO > .wrapper {
  width: 100% !important;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px !important;
  border-radius: 1rem !important;
}

.public-DraftStyleDefault-block {
  height: 6rem !important;
}

.questionAllocation {
  margin-top: 15px;
  text-align: start;
  padding: 15px;
  font-size: 14px;
  height: 110px;
  width: 400px;
}

/* .questionlists {
  width: 35%;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  border-left: 1px solid;
  padding-left: 20px;
} */
.Icondelete {
  float: right;
  cursor: pointer;
}

.MuiDialog-root {
  z-index: 99 !important;
}

/* Job Detail --> Description */
.job_header_sub_div span {
  font-size: 15px !important;
}

/* Question Bank --> Add question */
/* .multiple-choice {
  margin-left: -5rem;
} */
.option-choice #multiple-minus-circle {
  margin-left: 1rem;
}

.option-minus-circle {
  cursor: pointer;
  margin-top: 6px;
  margin-left: 10rem;
  font-size: large;
  margin-right: 2px;
}

.option-plus-circle {
  margin-left: 24rem;
}

/* Registration */
.anticon[tabindex] {
  margin-right: 5px;
}

/* TextEdit */
.quill {
  height: 11rem;
  margin-bottom: 4rem;
  border-radius: 4px;
  outline: none;
  border: 0;
  background-color: #fff;
}

@media screen and (max-width: 1000px) {
  .questionAllocation {
    height: 130px;
    width: 270px;
  }
}

@media screen and (max-width: 768px) {
  .questionAllocation {
    height: 174px;
    width: 155px;
  }
}

.ant-btn-primary {
  color: #fff;
  background-color: #0a7f8f !important;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

/* .public-DraftStyleDefault-block {
  height: 6px !important;
} */

.select_layout select {
  width: 98%;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid gray;
  outline: none;
  background-color: white;
}

.list_icon {
  flex-grow: 1;
  /* font-size: x-large; */
  cursor: pointer;
  color: #0c2556;
  /* padding: 5px; */
  border-radius: 5px;
  padding: 1px;
  margin: 3px;
}

/* Jobs --> Post a new job */
.wrapper {
  max-width: 100% !important;
}

.restButton {
  margin-left: 72%;
}

.activeButton {
  margin-left: 65%;
}
.ant-upload-wrapper .ant-upload-drag .ant-upload-btn {
  background-color: #fff !important;
}
.companyInfoDetails {
  margin: 40px;
  min-height: 80%;
  /* margin-top: 25px;
  margin-right: ;
  margin-bottom: 25px; */
  border-radius: 6px;
  border: 1px solid #dadada;
  box-shadow: 0px 4px 4px rgba(194, 194, 194, 0.25);
  background: transparent;
  /* bo: '1px solid #DADADA',
  boxShadow: '0px 4px 4px rgba(194, 194, 194, 0.25)',
  background: 'transparent' */
}

.wrapper {
  border-radius: 0.5rem !important;
  /* box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px !important;  */
}

.settingsCard {
  /* height: 94px; */
  border-radius: 10px;
}

.settingsPage {
  border-radius: 6px;
}

.plusIconsettings {
  width: 96px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #0a7f8f;
}
.settingsParameter {
  width: 85%;
  height: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}
button.ant-switch.css-dev-only-do-not-override-cnstt0 {
  background: #bfbfbf;
}
:where(
    .css-dev-only-do-not-override-cnstt0
  ).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #4096ff !important;
}
:where(
    .css-dev-only-do-not-override-cnstt0
  ).ant-switch.ant-switch-checked {
  background: #0a7f8f !important;
}
