
.status404_wraper{
    font-family: 'Poppins', sans-serif;
    background-color: #F8FBFF;
    height: 100vh;
    h2.expired-message {
        text-align: center;
        font-weight: 600;
        line-height: 40px;
        padding: 30px 0px 40px 0px;
        font-size: 2rem;
    }
    
    a.co-admin {
        text-align: center;
        display: block;
        font-size: 14px;
        text-decoration: none;
        color: #4E4E4E;
        line-height: 24px;
    }
    
    .admin-link {
        color: #0A7F8F;
        font-weight: 600;
    }
    
    h2.wrong-message {
        text-align: center;
        font-weight: 600;
        line-height: 40px;
        padding: 5px 0px 20px 0px;
        font-size: 2rem;
    }
    
    
    .button-sec {
        margin-top: 40px;
        text-align: center;
    }
    
    .yvi-card {
        width: 500px;
        margin-top: 4%;
        padding: 40px;
        border-radius: 8px;
        -webkit-box-shadow: 0px 8px 18px 1px rgba(176, 176, 176, 0.15);
        -moz-box-shadow: 0px 8px 18px 1px rgba(176, 176, 176, 0.15);
        box-shadow: 0px 8px 18px 1px rgba(176, 176, 176, 0.15);
        border: none;
        background-color: #fff;
    }
    
    section.card-sec {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .yvi-card-header {
        text-align: center;
    }
    
    p.company-details {
        font-size: 13px;
        text-align: center;
        padding-top: 15px;
        color: #5c5c5c;
    }
    
    .intro-image {
        text-align: center;
    }
    
    .cansel-btn {
        text-align: center;
    
        @media screen and (max-width: 425px) {
    
            .yvi-card {
                padding: 10px;
                top: 2%;
            }
    
        }
    }
}

