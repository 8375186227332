@use './CandidateStyles' as candidates;
@import './commonStyles.scss';

.custom-table .ant-table-thead > tr > th {
  background-color: rgb(237, 243, 255) !important;
}

tr.row-even-bgColor .ant-table-cell {
  background-color: #f9ffff !important;
}

tr.row-even-bgColor .ant-table-cell:hover {
  background-color: #f9ffff !important;
}

tr.ant-table-row-selected .ant-table-cell {
  background-color: rgba(74, 157, 168, 0.08) !important;
}

tr.ant-table-row-selected .ant-table-cell:hover {
  background-color: rgba(74, 157, 168, 0.08) !important;
}

tr.ant-table-row-selected .ant-table-cell-fix-right {
  background-color: #ffffff !important;
}
tr.ant-table-row-selected .ant-table-cell-fix-right:hover {
  background-color: #ffffff !important;
}

tr.ant-table-row-selected .ant-table-cell-fix-left {
  background-color: #ffffff !important;
}
tr.ant-table-row-selected .ant-table-cell-fix-left:hover {
  background-color: #ffffff !important;
}

tr.invalid {
  @include candidates.invalidRow;
}

tr.duplicate {
  @include candidates.duplicateRow;
}

// ---------- Starts :: For styling scroll bar if this class is given where ever needed ----------
.ant-table-body {
  position: relative;
  &::-webkit-scrollbar-track {
    background-color: #e7e5e5;
  }
  &::-webkit-scrollbar {
    background-color: #c1c1c1;
    border: 5px solid #e7e5e5;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border: 5px solid #e7e5e5;
    border-radius: 20px;
  }
}

.ant-table-sticky-scroll-bar {
  visibility: hidden;
}

.min-width-column {
  min-width: 80px !important;
}

.react-resizable-handle {
  right: 0 !important;
  cursor: col-resize !important;
  bottom: 0 !important;
  padding: 0px !important;
}

.react-resizable-handle:hover {
  cursor: col-resize !important;
}

.custom-popover .ant-popover-inner {
  position: absolute;
  right: 0;
}

.ant-table-filter-dropdown {
  // position: absolute !important;
  // right: -120px !important;
  min-width: 280px !important;
  z-index: 9999 !important;
}

.react-resizable-handle {
  opacity: 0.1;
}

.react-resizable-handle:hover {
  opacity: 0.9;
}

.candidate-section .datagrid-container {
  padding: 0px !important;
}

.grade-popup .ant-popover-inner {
  position: absolute !important;
  top: 0px !important;
  right: -33px !important;
}
.group-column {
  border: 3px solid #000;
}
