@import '../Styles/variables';
@import '../Styles/commonStyles.scss';

@mixin invalidRow {
  border-top: 1px solid #ff9966 !important;
  border-bottom: 1px solid #ff9966 !important;
  background-color: #ffe6d9 !important;

  &:hover {
    border-top: 1px solid #ff9966 !important;
    border-bottom: 1px solid #ff9966 !important;
    background-color: #ffe6d9 !important;
  }
}
@mixin duplicateRow {
  border-top: 1px solid #ece200 !important;
  border-bottom: 1px solid #ece200 !important;
  background-color: #fcfce2 !important;

  &:hover {
    border-top: 1px solid #ece200 !important;
    border-bottom: 1px solid #ece200 !important;
    background-color: #fcfce2 !important;
  }
}
